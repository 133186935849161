a{transition: all 0.2s ease-in;}
.gig_container{width: 1170px; margin: auto;}
.challenges .gig_container{margin-left: auto; margin-right: auto;}
.gig_container .text-center{text-align: center; margin: auto;}

.gig-hiring .main-content, .service-partners .main-content, .challenges .main-content{padding-bottom: 0;}

  .tacs_wrapper{font-family: 'Jost', sans-serif; font-size: 1em; color: #4E413B; transition: all 0.2s ease-in; padding-bottom: 3em;}
  .tacs_wrapper .row{display: flex; }
  .tacs_wrapper .row > div{align-self: center;}
  .tacs_wrapper img{max-width: 100%;}
  .trusted-by{padding-top: 0.4em;}
  .tacs_wrapper section:not(:last-child){margin-bottom: 5.5em;}
  .tacs-banner{display: flex; align-items: center; color: #fff; min-height: 88vh; background-image: url("https://api.cogniticx.com/app/media/uploads/tacs_images/tacs_banner.jpg"); background-size: cover; background-position: center center; background-repeat: no-repeat; }
  .tacs-banner.service-partner{background-image: url("https://api.cogniticx.com/app/media/uploads/tacs_images/service_partner_banner.jpg"); background-position: top center; }
  .tacs-banner h1{font-size: 6em; margin: 0; font-weight: 700; line-height: 1.05em; letter-spacing: 0.5px; margin-top: 0; text-shadow: 0 5px 12px #00000035;}
  .tacs-banner h4{font-weight: 300; font-size: 2.4em; font-weight: 600; text-shadow: 0 3px 8px #00000035; margin: 0;}
  .tacs-banner h5 {font-size: 1.75em; font-weight: 400; margin-top: 20px;}
  .tacs-banner.service-partner h1{font-size: 5.8em; font-weight: 600; line-height: 1.1em; letter-spacing: 0.5px; margin-top: 0; text-shadow: 0 5px 12px #00000035;}
  .tacs-banner.service-partner h4{font-weight: 300; font-size: 2em; text-shadow: 0 3px 8px #00000035;}
  .tacs-banner.service-partner h5{font-weight: 300; font-size: 1.6em; font-weight: 500; text-shadow: 0 3px 8px #00000035; color: #F07E22; line-height: 0;}

  .df-mt-1{margin-top: 1em;}
  .df-font-bold{font-weight: 600;}
  .df-color-fawn{color:#595959;}
  .df-color-orange{color:#F07E22;}
  .df-bg-rust{background-color:#A24921; color: #fff !important;}
  .df-bg-rust:hover{background-color:#962C08; color: #fff !important;}
  .df-bg-blue{background-color:#003965; color: #fff !important;}
  .df-bg-blue:hover{background-color:#012C4D; color: #fff !important;}
  .df-color-white{color: #fff !important;}
  .df-no-margin{margin-bottom: 0 !important;}
  .hero-buttons{display: flex; gap:10px; margin-top: 20px; margin-bottom: 100px;}
  .hero-buttons a{padding: 8px 25px; border-radius: 3px; font-size: 1.2em; border: solid 1px #fff; box-shadow: 0 11px 12px #00000045;}
  .gig_container .ml-10{margin-left: 160px;}

  .tacs-subheading{color: #962C08; font-size: 1.2em !important; letter-spacing: 1px; margin: 0; text-transform: uppercase;}
  .tacs-mainheading{color: #003965; font-size: 3em !important; font-weight: 600; line-height: 1.2em; margin-top: 5px; width: 70%; margin: auto;}
  .tacs-sectionheading{color: #003965; font-size: 2em !important; font-weight: 600; line-height: 1.2em;}
  .tacs-headingdesc{color: #464E5F; font-size: 1.2em !important; font-weight: 400;}

  .tacs-services{margin-top: 6em;}
  .tacs-services ul{font-size: 1.1em; margin-top: 1em; list-style-type: none; margin-bottom: 30px; padding-left: 30px;}
  .tacs-services ul li{line-height: 1.5em; margin-bottom: 0.5em; float: none;}
  .tacs-services ul li:before{content: ''; background-image: url('../images/check.svg'); width: 15px; height: 15px; background-position: center; background-repeat: no-repeat; background-size: contain; display: inline-block; left: -27px; position: relative; margin-right: -1em;}
  .tacs-services a{color: #962C08; font-weight: 600; margin-bottom: 3em; display: inline-block; font-size: 1em;}
  .tacs-services a:hover{color: #F07E22;}
  .tacs-services a svg{margin-bottom: -0.15em; fill:#962C08;}

  #client-carousel{ margin-top: 2em; display: flex; flex-wrap: wrap; row-gap:15px;}
  #client-carousel figure{flex-basis: 20%; text-align: center;}
  #client-carousel img{margin: auto; margin-top:15px;}
  /* .slick-dots li {width: 3px !important;} */

  .tacs-grey-bg{background-color: #F3F6F9; padding: 5em 0;}

  .how_it_works{margin-top: 2em;}
  .how_it_works_list{font-size: 1.1em; color: #003965; display: flex; gap:50px; padding-left: 0; width: 70%; margin: auto; padding-top: 1em;}
  .how_it_works_list li span{display: block;}
  .how_it_works_list li{counter-increment: tacs-counter; margin: 2em 0; float:none !important; list-style: none; position: relative; padding-left: 3em; flex-basis: 30%;}
  .how_it_works_list li:first-child{flex-basis: 38%;}
  .how_it_works_list li:before{content: counter(tacs-counter); display: inline-block; font-weight: bold; border-radius: 15px; color: #4E413B; position: absolute; left: 0; top: 0; line-height: 1em; margin-right: -4.2em; font-size: 3.5em;}


  .how_it_works_bullet{font-size: 1.1em; text-transform: capitalize; margin-top: 1em; list-style-type: none; margin-bottom: 30px;}
  .how_it_works_bullet li{line-height: 1.5em; margin-bottom: 0.5em;}
  .how_it_works_bullet li:before{content: ''; width: 11px; height: 11px; display: inline-block; background-color:#962C08; border-radius: 7.5px; left: -27px; position: relative; margin-right: -1em;}

.final-text{font-size: 2em; font-weight: 500; text-align: center; color: #6E939C; width: 70%; margin: auto; line-height: 1.4em;}

  .tacs-divider{height: 1px; background-color: #EAEAEA;}

  .tacs-steps-wrap{display: flex; flex-wrap: wrap; position: relative; justify-content: center;}
  .tacs-steps-wrap .tacs-step-path{flex-basis: 100%; height: 2px; border-top: dashed 2px #A3A3A350; position: absolute; top: 5em; width: 80%; z-index: 0;}
  .tacs-steps{ flex-basis: 20%; text-align: center; font-weight: 600; color: #003965; margin-top: 4em; padding: 0 2em; z-index: 1}
  .tacs-steps p{font-weight: 400; color:#828282; }
  .tacs-steps .tac-step-icon figure{background-color: #FFF1DA; height: 65px; width: 65px; border-radius: 2.5em; margin: auto; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center; align-content: center;}
  .tacs-steps .tac-step-icon h3{ font-size: 23px; font-weight: 500; line-height: 1.2; color: #003965; margin-top: 10px;}


  @media only screen and (max-width:767px){
    .tacs_wrapper section {overflow: hidden;margin-bottom: 2em;}
    .mb-reverse{ flex-direction: column-reverse !important;}
    .mb-container{width: 100%;}
    h4.mb-sm{font-size: 1em;}
    .tacs-banner .mb-50 {margin-left: 7em; margin-top: 4em;}
    .tacs-banner h1 {font-size: 2.5em !important;}
    .tacs-banner h4 {font-size: 1.5em !important;}
    .mb-row .row {flex-direction: row !important; padding-bottom: 0 !important;}
    .tacs-banner {min-height: 414px; background-position: top left; align-items: flex-start; text-align: center;}
    .hero-buttons a {padding: 8px 20.5px; font-size: 1em;}
    /* .tacs-banner h4 {width: 55%; line-height: 1.2em;} */
    .hero-buttons {justify-content: center;}
    .tacs_wrapper .row{flex-direction: column;}
    .tacs-mainheading {font-size: 1.4em !important; width: 100%;}
    .tacs-subheading {font-size: 1em !important;}
    .tacs-sectionheading {font-size: 1.3em !important;}
    .challenges .tacs-sectionheading {font-size: 1.25em !important; margin-top: 0 !important; margin-bottom: 5px;}
    .tacs-headingdesc {font-size: 1em !important;}
    .tacs-services {margin-top: 2em; padding-left: 15px; padding-right: 15px;}
    .tacs-services ul {font-size: 1em !important;}
    .tacs_wrapper img {max-width: 100%; margin-bottom: 10px;}
    .tacs-icon-box-wrap .tacs-icon-box {padding-right: 1.8em;}
    .tacs-icon-box-wrap .tacs-icon-box h2 {font-size: 1.1em;}
    .tacs-grey-bg{padding: 2em 0 !important;}
    .how_it_works {margin-top: 0;}
    .how_it_works_list{display: flex; padding: 0; font-size: 1em; line-height: 1.2em; width: 100%; gap: 10px;}
    .how_it_works_list li{flex-basis: 33%; padding-left: 1.1em;}
    .how_it_works_list li:before {font-size: 1.5em;}
    .how_it_works_bullet {margin-left: 28px;}
    .how_it_works_bullet li:before { margin-right: -0.7em;}
    .tacs-review-title svg {flex-basis: 23%;}
    .tacs-reviews{margin-top: 2em;}
    .tacs-review-title h3{font-size: 1.2em;}
    .tacs-talent-strategy {padding: 2em 0;}
    .tacs-steps {margin-top: 1em; padding: 0; flex-basis: 100%;}
    .tacs-steps h3{font-size: 1em;}
    .tacs-steps p{text-align: left; flex-basis: 60%; line-height: normal; font-size: 1.1em;}
    .tacs-steps .tac-step-icon{display: flex;}
    .final-text {font-size: 1.3em;  width: 100%; line-height: normal;}
    .tacs-steps .tac-step-icon figure{height: 45px; width: 45px; flex-basis: 15%; margin-right: 10px; margin-left: 0;}
    .tacs-steps .tac-step-icon figure img{max-width: 85%; margin-bottom: 0;}
    .tacs-steps .tac-step-icon h3{ flex-basis: 70%; text-align: left; font-size: 1.2em;}
    .mb-left{text-align: left;}
    .ml-10{margin-left: 0;}
  }

  /* **************** */

  .gig_container  .ml-5{margin-left: 50px;}
  .gig_container  .ml-20{margin-left: 200px;}
  .gig_container  .ml-30{margin-left: 300px;}
  .gig_container  .ml-10{margin-left: 100px;}
  .gig_container  .mt-4{margin-top: 20px;}

  .width_90{width: 90%;}
  .width_50{width: 50%;}
  .w_1k{max-width: 1100px; margin: auto;}
  .margin_top_6{margin-top: 6em;}
  .margin_top_5{margin-top: 5em;}

  .tacs-grey-bg{background-color: #FCFCFC; padding: 5em 0;}
  .tacs-headingdesc{color: #464E5F; font-size: 1.3em; font-weight: 400; line-height: 1.3em; width: 65%; margin: 0 auto;}

  .bg-wify{height: 800px; background-image: url('https://api.cogniticx.com/app/media/uploads/tacs_images/wify_aquireskills.png'); background-repeat: no-repeat; background-position: bottom center; background-size: auto; margin-bottom: 0 !Important;}
  .bg-branding{height: 1160px; background-image: url('https://api.cogniticx.com/app/media/uploads/tacs_images/zero_cost.jpg'); background-repeat: no-repeat; background-position: bottom center; background-size: auto; margin-bottom: 0 !Important;}

  .cta_button_wrapper{width: 100%; margin: auto; text-align: center;}
  .cta_button{background-color:#962C08; padding: 10px 20px; color: #fff !important; display: inline-block; text-align: center;}
  .service-partners .cta_button span {font-weight: 700;font-size: 1.2em; display: block; }
  .cta_button:hover{background-color:#A24921; padding: 10px 20px; color: #fff; display: inline-block; text-align: center;}

  .bg-zero_cost{margin-top: -5.6em;}
  .bg-zero_cost h2{color: #fff !important; font-size: 5.5em; line-height: 1.4em;  }
  .bg-zero_cost h3{color: #A24921 !important; font-size: 2em; font-weight: 600;}
  .bg-zero_cost p{font-size: 1.3em; }


  @media only screen and (max-width:767px){
    .margin_top_6{margin-top: 2em;}
    .margin_top_5 {margin-top: 2em;}
    .service-partners .bg-wify .margin_top_5 {margin-top: 0;}
    .order-1{order: 1; margin-bottom: 1em;}
    .order-2{order: 2;}
    .order-3{order: 3;}
    .tacs-banner h4 {font-size: 1.5em;}
    .service-partners .tacs-banner {min-height: 380px; background-position: center bottom; align-items: flex-start; text-align: center; padding-top: 10px;}
    .max-w-1100 .row {padding-bottom: 1em;}
    .tacs-headingdesc {font-size: 1em;}
    .tacs_wrapper img {max-width: 100%; margin-bottom: 10px;}
   .gig_container .ml-10, .gig_container .ml-20, .gig_container .ml-5, .gig_container .ml-30{margin-left: 0;}
    .tacs-headingdesc { width: 100%;}
    .bg-wify, .bg-branding { height: 420px; background-size: contain;}
    .bg-zero_cost h2 {font-size: 2.3em;}
    .bg-zero_cost {  margin-top: -2.6em;}
    .bg-zero_cost h3 {font-size: 1.5em; width: 80%; margin: auto; padding-bottom: 10px;}
    .bg-zero_cost p {font-size: 1em; width: 80%; margin: auto; padding-bottom: 10px;}
    
  }

  /* =========== Challenges CSS ============= */

.challenges .col-md-9 {width: 75%;}
.challenges .col-md-7 {width: 58.33333333%;}
.challenges .col-md-3 {width: 25%;}
.challenges .col-md-5 {width: 41.66666667%;}
.challenges .col-md-8 {width: 66.66666667%;}
.challenges .col-md-2 {width: 16.66666667%;}

    .challenges .tacs-banner{align-items: flex-start !important; background-image: url("https://api.cogniticx.com/app/media/uploads/tacs_images/tacs_banner-challenegs.jpg"); background-position: center center; }
    .challenges .tacs-banner h1{font-size: 6.2em; font-weight: 600; line-height: 0.95em; letter-spacing: 0.5px; margin-top: 0; text-shadow: 0 5px 12px #00000035;}
    .challenges .tacs-banner h4{font-weight: 300; font-size: 2em; font-weight: 500; text-shadow: 0 3px 8px #00000035;  margin-top: 2em;}

    .gig-challenges{margin-top: 6em !important; max-width: 1024px;}
    .gig-challenges .row{padding-bottom: 3em;}
    .gig-challenges .row > .col-md-7{padding-right: 15em;}
    .gig-challenges img{height: 58px;}
    .gig-challenges .row div{align-self: self-start;}

.challenges .max-w-1100 .row {padding-bottom: 5em; }
.challenges .max-w-1100 {max-width: 1060px;}
.challenges .w-50{padding-right: 15px; padding-left: 15px;}



    /* .tacs-subheading{color: #962C08; font-size: 1.3em; letter-spacing: 1px; margin: 0; text-transform: uppercase;}
    .tacs-mainheading{color: #003965; font-size: 4.4em; font-weight: 600; line-height: 1.2em; padding-top: 5px; margin: auto;}
    .tacs-sectionheading{color: #962C08; font-size: 2.1em; font-weight: 600; line-height: 1.2em;}
    .tacs-headingdesc{color: #464E5F; font-size: 1.5em; font-weight: 400;} */

    .gig-challenges ul{font-size: 1.2em; margin-top: 1em; list-style-type: none; margin-bottom: 30px; padding-left: 1em; font-weight: 500; position: relative;}
    .gig-challenges ul li{line-height: 1.5em; margin-bottom: 0.5em; padding-left: 1em; float: none;}
    .gig-challenges ul li:before{content: '!'; display: inline-block; font-size: 1em; font-weight: 700; line-height: 1.16em; color:#F07E22; left: -3px; position: absolute; background-color: #FDD9CD; width: 23px; height: 23px; border-radius: 11px; text-align: center;}
    .gig-challenges a{color: #962C08; font-weight: 600; margin-bottom: 5em; display: inline-block; font-size: 1.1em;}
    .challengess a:hover{color: #F07E22;}

    .challenges .margin_top_2 { margin-top: 2em;}

    .challenges .tacs-sectionheading{color: #962C08 !important; margin-top: 20px; font-size: 1.8em;}


    .talent_5_0 .row > div{align-self: flex-start;}
    .talent_5_0 ul{font-size: 1.3em; color: #003965; padding-top: 1em; list-style: none; color: #464E5F; position: relative; padding-left: 25px;}
    .talent_5_0 li span{display: block; font-size: 19px !important;}
    .talent_5_0 ul li{line-height: 1.3em; margin-bottom: 2.5em; float: none; position: relative;}
    .talent_5_0 ul li:before, .actionable_insights ul li:before{content: ''; background-image: url('../images/check.svg'); width: 15px; height: 15px; background-position: center; background-repeat: no-repeat; background-size: contain; left: -20px; top: 5px; position: absolute; margin-right: -1em;}

    .actionable_insights ul{font-size: 1.2em; font-weight: 500; color: #003965; display: flex; gap:80px; padding-left: 0; width: 70%; margin: auto; padding-top: 1em; list-style: none; float: none;}
    .actionable_insights ul li{position: relative; padding-left: 5px; float: none;}


    .talent_options{display: flex; gap:20px; padding: 0 20px;}
    .talent_options img{border-radius: 5px;}
    .talent_options > div{ align-self: center; color: #464E5F;}
    .talent_options p{font-size: 1.1em;}
    .talent_options  h3{margin: 0;}



    @media only screen and (max-width:767px){
      .challenges .tacs-banner h4 {margin-top: 0; margin-bottom: 5px; width: 100%; }
      .challenges .tacs-banner {text-align: center; min-height: 320px;}
      .gig-challenges .row > .col-md-7 {padding-right: 0; padding-bottom: 2em;}
      .gig-challenges .row{padding-bottom: 1em;}
      .gig-challenges ul{font-size: 1.1em;}
      .talent_5_0 ul li {margin-bottom: 1.5em; float: none;}
      .talent_5_0 ul {padding-top: 0; margin-left: 1.5em;}
      .actionable_insights ul {flex-direction: column; width: 100%; text-align: left; gap: 11px; margin-left: 1.5em; padding-top: 0;}
      .gig-challenges {margin-top: 2em !important;}
      .talent_options {flex-direction: column; gap:10px; padding: 0;}
      .talent_options p {font-size: 1em;}
      .challenges .tacs-services {margin-top: 2em;}
      .challenges .tacs-services ul {font-size: 1em;}
      .challenges .col-md-9, .challenges .col-md-7, .challenges .col-md-3, .challenges .col-md-5, .challenges .col-md-8, .challenges .col-md-2, .challenges .width_50 {width: 100% !important; padding-left: 15px; padding-right: 15px;}
      .tacs-banner.service-partner h4{margin-top: 20px;}
      .tacs-banner.service-partner h5 {font-size: 1.4em;}

    }
