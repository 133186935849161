iframe{display:none !important;}

.container{margin:0 auto; width:1120px;}
.container-lg{margin:0 auto; width:1240px;}
.half-container{width:calc(100% - 560px);}

.ant-modal-body .container{width:95%}
.ant-modal.no-title .ant-modal-body{padding:0}
.ant-modal.no-title .ant-modal-close{position:absolute; top:10px; right:10px;}
.ant-modal.no-title .ant-modal-close-x{width:40px; height:40px; background-color:rgba(255,255,255,.7); border-radius:50%; display:flex; align-items:center; justify-content:center; box-shadow:0 0 5px rgba(0,0,0,.5);}

.main-content{padding-bottom:35px;  }
.cms-page .main-content, .how-we-work-page .main-content{padding-bottom:0; }
.main-content-full{}
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 21px 0;
}

.main-content-title{margin-top:25px; margin-bottom:25px}
.main-content-title h1{font-weight:600; font-size:30px; color:#003965; margin:0}
.post-project .main-content-title h1{font-weight:600; font-size:40px; color:#003965; margin-top:40px;}
.main-content-title .page-subtitle{color:#565656; font-weight:normal; font-size: 16px;}

.ant-select-dropdown {z-index: 9999 !important;}

.homepage .main-content{padding:0; background:#fff}
.dark-blue-btn:hover, .ant-btn-primary.dark-blue-btn:hover, .ant-btn-primary.dark-blue-btn:focus, .ant-btn-primary.dark-blue-btn:active{background-color: #962c08; color: #fff; border: none;}

.items-end{justify-content:flex-end;}
.self-center{margin:0 auto; align-self:center}
.self-right{align-self:flex-end}

.d-inline{display:inline-block;}
.flex,
.d-flex{display:flex !important;}
.d-flex-column{display:flex; flex-direction:column;}
.rev-row{display:flex; flex-direction:row-reverse;}
.rev-column{display:flex; flex-direction:column-reverse;}
.justify-center{display:flex; align-items:center; justify-content:space-between;}
.justify-content-between{justify-content:space-between !important;}
.justify-content-center{justify-content:center !important;}
.justify-content-start{justify-content:flex-start !important;}
.justify-content-end{justify-content:flex-end !important;}
.align-start{align-items:flex-start;}
.align-end{align-items:flex-end;}
.align-center{display:flex; justify-content:center; align-items:center;}
.align-right{display:flex; align-items: flex-end;}
.vertical-align{vertical-align: middle;}
.items-center{align-items:center !important}
.wrap{flex-wrap:wrap}
.fb-50{flex-basis:50%;}
.gap-30{gap: 30px;}
.gap-20{gap: 20px;}
.gap-10{gap: 10px;}
.gap-5{gap: 5px !important;}
.gap-x-13{column-gap: 13px;}

.text-center{text-align:center;}
.text-left{text-align:left;}
.text-right{text-align:right;}

.vertical{flex-direction:column;}
.vertical-reverse{flex-direction:column-reverse;}

.flex-start{display:flex; justify-content:flex-start; align-items:center}
.justify-between{display:flex; justify-content:space-between; align-items:center}
.justify-center{display:flex; justify-content:center; align-items:center}
.justify-start{display:flex; justify-content:flex-start; align-items:flex-start}
.justify-end{display:flex; justify-content:flex-end; align-items:flex-end}

.flex-one{flex:1}
.flex-20{flex:20%;}

.linkedin-fill svg{fill: #0A66C2;}

.ant-radio-checked::after{border:1px solid #003965}
.ant-radio-inner::after{background-color:#003965;}
.ant-radio-checked .ant-radio-inner{border-color:#003965}

.ant-input, .auth-block .ant-input-number-input{background-color:#F8F8F8; font-size:16px; padding:8px 15px;}
.auth-block .ant-input-number-input{min-height: 46px;}
#register_form .form-item-block.d-flex{gap: 10px;}
.ant-input:hover,
.ant-picker:hover,
.ant-picker-focused,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input-number:hover{border-color:#003965}

img,
.img-fluid{max-width:100%; object-fit: cover;}

a{color:#236eaf; text-decoration:none;}
a:hover{color:inherit;}

.card{padding:35px; background-color:#fff; box-shadow: 0px 6px 15px rgb(0 0 0 / 5%);}

.ant-btn-primary{background-color:#003965; border:1px solid #003965; border-radius:3px; font-weight:500; font-size:15px; text-shadow: none;}
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active{background-color:#0a4c7e; border:1px solid #003965; color:#fff;}

.ant-btn-primary.ant-btn-circle{border-radius:50%}

.ant-btn-sm{}

.ant-btn-secondary{background-color:#fff; border:1px solid #003965; color:#003965; text-transform:uppercase; border-radius:5px}
.ant-btn-secondary:hover{background-color:rgb(0, 57, 101, .2); border:1px solid #003965; color:#003965}

.header-alerts .ant-alert-with-description{position:fixed; right:15px; top:15px; width:500px; z-index:99999; box-shadow:0 0 15px rgba(0,0,0,.05);}

.ant-btn-lg{padding:10px 25px; height:auto;}
.btn-wide{width:100%;}

.dropdown-menu{padding:0}
.dropdown-menu .ant-dropdown-menu-item,
.dropdown-menu .ant-dropdown-menu-submenu-title{padding:0}

.dropdown-menu .ant-dropdown-menu-item button{width:100%; border:0 none; padding:10px 30px; text-align:left;}
.dropdown-menu .ant-dropdown-menu-item button:hover{background-color:rgba(0, 0, 0, .01);}

input,
select,
textarea{border:1px solid #e3e3e3; border-radius:4px;}
.form-control{color:#adaaaa; font-size:14px; padding:10px 15px; width:100%; margin-bottom:15px;}

.ant-input::placeholder{color:#bfbfbf;}
.ant-input-number-input::placeholder{color:#bfbfbf;}

/* .ant-select-multiple .ant-select-selector{border:1px solid #F8F8F8; border-left:3px solid #F8F8F8; background-color:#F8F8F8} */

.ant-input-number{border:1px solid #fff;}
.ant-input, .ant-input-affix-wrapper{background-color:#fff; }
.ant-input, .ant-picker, input.ant-input-number-input {background-color: #fff !important; border: 1px solid #d9d9d9; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{ border:1px solid #f8f8f8}

.ant-input-affix-wrapper:hover{border-color:#003965; border-left:3px solid #003965;}
.ant-input-affix-wrapper:focus{border-color:#fff; border-left:3px solid #F07E22;}

.post-project-extras-page-1 .ant-form-item{margin-bottom: 0;}
.post-project .ant-steps-small .ant-steps-item-title{font-size: 16px;}

.ant-input-affix-wrapper-focused.ant-input-affix-wrapper:hover{border-color:#d9d9d9 !important; border-left:3px solid #F07E22 !important;}

.ant-input,
.ant-input-number-input{background-color:#F8F8F8; /*border:1px solid #F8F8F8; border-left:3px solid #F8F8F8;*/ position:relative;}
.ant-form-item-label > label{color:#003965}
.ant-input:focus,
.ant-input-focused,
.ant-input-number-focused,
.ant-input-affix-wrapper-focused{border-color:#d9d9d9; box-shadow: 0px 4px 12px rgb(0 0 0 / 6%); background-color:#fff; border-left:3px solid #F07E22}

.form-item-block.d-flex .ant-form-item{}

.ant-radio-wrapper .ant-radio{vertical-align:middle;}
.ant-picker{width:100%;}
.ant-input-number{width:100%;}
.ant-form-item .ant-upload.ant-upload-drag{background:#fff;}
.ant-btn-ghost{box-shadow:none; padding:15px 0px;}

.message-action .ant-btn:hover, .message-action .ant-btn:active, .message-action .ant-btn:focus{border-color: #f07e22; color: #f07e22;}

.products-listing.products_offers .single-offer{padding: 0; padding-bottom: 20px;}
.products-search-section .single-offer{padding-bottom: 0;}
.products-listing.products_offers .single-offer .title, .products-search-section .single-offer .title{font-size: 20px !important; min-height: 48px;}
.products-listing.products_offers .single-offer .top-block, .products-search-section .single-offer .top-block{height: 200px;}
.products-listing.products_offers .single-offer .mid-block, .products-search-section .single-offer .mid-block, .products-listing.products_offers .single-offer .bottom-block, .products-search-section .single-offer .bottom-block, .products-offers-tab .single-offer .mid-block{padding: 0 0;}

.blk-slider.our-team.about .img-block a{width: 20%;}

.w-auto{width:auto !important;}
.w-18{width:18%;}
.w-23{width:23%;}
.w-20{width:20%;}
.w-25{width:25%;}
.w-30{width:30%;}
.w-33{width:33%;}
.w-35{width:35%;}
.w-40{width:38%;}
.w-42{width:45%;}
.w-45{width:45%;}
.w-48{width:48%;}
.w-50{width:50%;}
.w-55{width:55%;}
.w-58{width:58%;}
.w-65{width:65%;}
.w-70{width:70%;}
.w-80{width:80%;}
.w-100{width:100%;}

.w-100v{width:100vw;}

.w-30p{width:30px;}
.w-40p{width:40px;}
.w-60p{width:60px;}
.w-65p{width:65px;}

.h-100{height:100%;}
.h-82{min-height: 82px;}

.h-auto{height: auto;}
.h-30p{height:30px;}
.h-40p{height:40px;}
.h-55p{height:55px;}
.h-65p{height:65px;}
.h-205p{height:205px;}
.h-320p{height:320px;}

.fw-300{font-weight:300;}
.fw-400{font-weight:400;}
.fw-500{font-weight:500;}
.fw-600{font-weight:600;}
.fw-700, .fw-b{font-weight:700;}

.p-5{padding:5px;}
.p-10{padding:10px;}
.p-15{padding:15px;}
.p-20{padding:20px;}
.p-25{padding:25px;}
.p-30{padding:30px;}
.p-35{padding:35px;}
.p-40{padding:40px !important;}
.p-70{padding:70px;}

.pb-0{padding-bottom:0px !important;}
.pb-5{padding-bottom:5px;}
.pb-10{padding-bottom:10px;}
.pb-15{padding-bottom:15px;}
.pb-20{padding-bottom:20px;}
.pb-25{padding-bottom:25px;}
.pb-50{padding-bottom:50px;}
.pb-80{padding-bottom:80px;}

.pt-0{padding-top:0px !important;}
.pt-5{padding-top:5px;}
.pt-10{padding-top:10px;}
.pt-15{padding-top:15px;}
.pt-20{padding-top:20px;}
.pt-25{padding-top:25px;}
.pt-50{padding-top:50px;}
.pt-80{padding-top:80px;}
.pt-100{padding-top:100px;}

.pl-10{padding-left:10px;}
.pl-20{padding-left:20px;}
.pl-25{padding-left:25px;}
.pl-60{padding-left:60px;}
.pl-15{padding-left:15px;}
.pr-50{padding-right:50px;}
.pr-20{padding-right:20px;}

.px-5{padding-left:5px; padding-right:5px;}
.px-10{padding-left:10px; padding-right:10px;}
.px-15{padding-left:15px; padding-right:15px;}
.px-20{padding-left:20px; padding-right:20px;}
.px-25{padding-left:25px; padding-right:25px;}
.px-30{padding-left:30px; padding-right:30px;}
.px-50{padding-left:50px; padding-right:50px;}

.py-3{padding-bottom:3px; padding-top:3px;}
.py-5{padding-bottom:5px; padding-top:5px;}
.py-8{padding-bottom:8px; padding-top:8px;}
.py-10{padding-bottom:10px; padding-top:10px;}
.py-15{padding-bottom:15px; padding-top:15px;}
.py-20{padding-bottom:20px; padding-top:20px;}
.py-25{padding-bottom:25px; padding-top:25px;}
.py-30{padding-bottom:30px; padding-top:30px;}
.py-40{padding-bottom:40px; padding-top:40px;}
.py-50{padding-bottom:50px; padding-top:50px;}

.mt-0{margin-top:0px;}
.mt-2{margin-top:2px;}
.mt-5{margin-top:5px;}
.mt-10{margin-top:10px;}
.mt-15{margin-top:15px;}
.mt-20{margin-top:20px;}
.mt-30{margin-top:30px;}
.mt-25{margin-top:25px;}
.mt-40{margin-top:40px;}
.mt-50{margin-top:50px;}
.mt-65{margin-top:65px;}
.mt-75{margin-top:75px;}
.mt-80{margin-top:80px;}
.mt-100{margin-top:100px;}

.mt--125{margin-top:-125px;}

.mb-0{margin-bottom:0px;}
.mb-5{margin-bottom:5px;}
.mb-10{margin-bottom:10px;}
.mb-15{margin-bottom:15px;}
.mb-20{margin-bottom:20px;}
.mb-25{margin-bottom:25px;}
.mb-30{margin-bottom:30px;}
.mb-40{margin-bottom:40px;}
.mb-50{margin-bottom:50px;}
.mb-75{margin-bottom:75px;}

.my-10{margin-top:10px; margin-bottom: 10px;}
.my-20{margin-top:20px; margin-bottom: 20px;}

.ml-3{margin-left:3px}
.ml-5{margin-left:5px}
.ml-10p{margin-left:10px}
.ml-10{margin-left:10px}
.ml-15{margin-left:15px}
.ml-20{margin-left:20px}
.ml-25{margin-left:25px}
.ml-30{margin-left:30px}

.mr-0{margin-right:0px}
.mr-5{margin-right:5px}
.mr-10{margin-right:10px}
.mr-15{margin-right:15px}
.mr-20{margin-right:20px}
.mr-25{margin-right:25px}
.mr-30{margin-right:30px}
.mr-50{margin-right:50px}

.fs-10{font-size:10px;}
.fs-11{font-size:11px;}
.fs-12{font-size:12px;}
.fs-13{font-size:13px;}
.fs-14{font-size:14px;}
.fs-15{font-size:15px;}
.fs-16{font-size:16px;}
.fs-18{font-size:18px;}
.fs-19{font-size:19px;}
.fs-20{font-size:20px;}
.fs-22{font-size:22px;}
.fs-24{font-size:24px;}
.fs-26{font-size:26px;}
.fs-28{font-size:28px;}
.fs-30{font-size:30px;}
.fs-32{font-size:32px;}
.fs-35{font-size:35px;}
.fs-40{font-size:40px;}
.fs-60{font-size:60px;}

.c-o{color:rgb(240, 126, 34)}
.c-lb{color:#6e939c}
.c-b{color:#003965}
.c-w{color:#fff}
.c-brown{color:#a24921}
.c-dbrown{color:#962c08;}
.c-g{color:#818181;}
.c-dg2{color:#434343;}
.c-dg3{color: #1b1b1b;}
.c-dg{color:#464E5F;}
.c-green{color:rgb(71, 163, 9);}

.bg-white{background-color:#fff}
.bg-sk{background-color:#fbf2eb}
.bg-lo{background-color:#FFF1DA;}
.bg-o{background-color:#f07e22}
.bg-b{background-color:#003965}
.bg-w{background-color:#fff}
.bg-lbrown{background-color:#928A87;}
.bg-brown{background-color:#a24921}
.bg-dbrown{background-color:#962c08}
.bg-lg{background-color:#F8F8F8;}
.bg-green{background-color:rgb(71, 163, 9);}

.brc-o{border-color:#f07e22}
.brc-b{border-color:#003965}
.brc-w{border-color:#fff}
.brc-brown{border-color:#a24921}
.br-brown{border: solid 1px #a24921}

.border-top-1{border-top:1px solid rgba(0, 0, 0, .1)}
.border-top-g1{border-top:1px solid rgb(71, 163, 9, .1)}
.border-orange{border: solid 1px #f07e22ab; box-shadow: 1px 3px 9px #3535353b;}
.border-none{border: none;}

.btn.bg-b:hover{background-color:#003965; border-color:#003965; color:#fff; opacity:.9;}
.btn.bg-w:hover{background-color:#a24921; border-color:#a24921; color:#fff;}
.btn.bg-brown:hover{background-color:#fff; border-color:#a24921; color:#a24921;}

.sub-chat-messages textarea{border: none;}
.sub-chat-messages .message-footer{background-color: #fff !important;}

.op-9{opacity:.9;}
.op-8{opacity:.8;}
.op-7{opacity:.7;}
.op-6{opacity:.6;}
.op-5{opacity:.5;}
.op-4{opacity:.4;}
.op-3{opacity:.3;}

.br-2{border-radius:2px;}
.br-5{border-radius:5px; overflow: hidden;}
.br-10{border-radius:10px;}
.br-20{border-radius:20px;}
.round{border-radius:50%;}

.lh-1{line-height:1;}

.box-white{background-color:#fff; padding:25px;}
.freelancers-profile-right-section .box-white{padding:32px;}
.proposal-overview .freelancers-profile-right-section .box-white{padding:0 32px 0 0;}
.bordered{border:1px solid rgba(0, 0, 0, .1)}
.border{border:1px solid;}
.bordered-bottom{border-bottom:1px solid rgba(0, 0, 0, .1)}
.border-0{border:0px none;}

.shadow{box-shadow:5px 5px 5px rgba(0, 0, 0, .05)}
.bigshadow{box-shadow:0px 5px 13px rgba(0, 0, 0, .05)}

.bc-ccc{border-color:#ccc;}

.relative{position:relative;}
.absolute{position:absolute;}
.absolute-bottom-right{position:absolute; bottom:4px; right:4px;}

.l-h-1{line-height:1}
.l-h-11{line-height:1.1}
.l-h-12{line-height:1.2}
.l-h-14{line-height:1.4}
.l-h-15{line-height:1.5}

.ov-hidden{overflow:hidden;}
.txt-capitalize{text-transform:capitalize;}
.txt-uppercase{text-transform:uppercase;}

.index-100{z-index:100;}

.line-after::after{background-color:#fc9e00; bottom:-10px; content:""; display:block; height:3px; left:calc(50% - 40px); position:absolute; width:80px;}

.feather{margin-right:5px; vertical-align:middle; width:20px;}
.profile-details .feather{width:auto;}

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');

.wd-loader-block{position:absolute; width:100%; height:100%; display:flex; top:0; left:0; z-index:999; background-color:rgba(255,255,255,.1); justify-content:center; align-items:flex-start;}
.wd-loader{width:150px; height:150px; margin-top:100px}
.wd-loader-block-sm{height:auto; padding:10px 0}
.wd-loader.wd-loader-sm{width:50px; height:50px;}

.btn-github{background-color:#000;}

#login_form .ant-form-item:last-child{margin-bottom: 0;}
.btn-linked-in{width: 100%; padding: 10px 25px; border: solid 2px #0A66C2; font-weight: 400; border-radius: 5px; display: flex; color: #fff; fill: #fff; justify-content: center; gap: 10px; transition: all 0.4s ease-out; background-color: #0A66C2; cursor: pointer;}

.btn-linked-in:hover{background-color:#006097;}

.ico{background-image:url('../images/icons.png'); background-repeat: no-repeat; display:inline-block;}
.ico.facebook{background-position:-13px -8px; height:18px; width:10px;}
.ico.twitter{background-position:-42px -7px; height:18px; width:20px;}
.ico.linkedin{background-position:-78px -6px; height:18px; width:20px;}
.ico.youtube{background-position:-14px -263px; height:18px; width:24px;}

.ico.user{background-position:-109px -40px; height:10px; width:11px;}
.ico.clock{background-position:-221px -87px; height:13px; width:13px;}
.ico.heart{background-image:url('../images/heart-icon.svg'); height:14px; width:14px; background-position: center; background-size: contain; background-repeat: no-repeat; margin-top: -3px; vertical-align: middle;}
.ico.email{background-image:url('../images/proposals-icon.svg'); height:15px; width:15px; background-position: center; background-size: contain; background-repeat: no-repeat; margin-top: -3px; vertical-align: middle;}

.ico.shield{background-position:-2px -71px; height:63px; width:63px;}
.ico.camera{background-position:-70px -71px; height:63px; width:63px;}
.ico.hand{background-position:-142px -71px; height:63px; width:63px;}
.ico.blue-arrow{background-position:-110px -10px; height:16px; width:28px;}

.ico.share-research{background-position:-8px -151px; height:93px; width:89px;}
.ico.track-change{background-position:-104px -152px; height:85px; width:97px;}
.ico.mining-traffic{background-position:-208px -152px; height:90px; width:87px;}

.ico.sold{background-position:-347px -2px; height:30px; width:32px;}
.ico.offer-green-check{background-position:-146px -39px; height:17px; width:17px;}

.ico.sold-fill{background-position:-242px -86px; height:18px; width:20px;}
.ico.clock-blue{background-position:-267px -110px; height:13px; width:13px;}
.ico.eye-blue{background-position:-242px -108px; height:14px; width:20px;}
.ico.heart-blue{background-position:-221px -110px; height:14px; width:14px;}

.ico.jpg-file{background-position:-340px -87px; height:21px; width:19px;}
.ico.word-file{background-position:-309px -87px; height:21px; width:22px;}
.ico.png-file{background-position:-366px -87px; height:21px; width:20px;}

.no-data-container img{opacity: 0.3;}
/* .no-data{width:400px;} */
.no-data .anticon svg{width: 0.3em !important;}
.no-data{align-items: center;}

.no-data img{width:100%; max-width:50%}
.no-data svg{font-size:100px; color:rgba(0, 0, 0, .1)}
.no-data p{font-weight:bold; font-size:20px; color:rgba(0, 0, 0, .2); text-transform:capitalize; margin-top:20px}

/* .no-data-container.small{margin-top:50px} */
.no-data-container.small img{width:30px; object-fit: contain; }
.no-data-container.small p{font-size:17px; color:rgba(0, 0, 0, .3); font-weight:normal;}
.no-data-container.medium{margin-top:50px;}
.no-data-container.medium img{width:200px;}
.no-data-container.medium p{font-size:18px; color:rgba(0, 0, 0, .3); font-weight:normal}

.hide{display:none;}
ul{margin-bottom:0px; padding-left:0px; list-style-type:none;}
ul[type=disc]{list-style-type:disc; padding-left:20px;}
.pointer{cursor:pointer;}

.ant-list-items{min-height:220px;}
.h-auto .ant-list-items{min-height:inherit;}

.hoverable{transition:.2s all ease-in-out; border-radius:5px}
.hoverable:hover{background-color:rgba(0, 0, 0, .05);}

.small-heading{color:#962c08; font-size:22px; text-transform: uppercase;}
.heading{color:#003965; font-size: 40px; font-weight: 600; line-height: 1.1em;}
.bg-white,
.white-bg{background-color:#fff;}
.inner-page{padding:45px 0;}
.inner-page .heading{color:#003965; font-size:35px !important; line-height:1.2; padding-bottom: 0.4em;}
.related-projects-section .heading{font-size: 28px !important; margin-bottom: 10px;}
.related-projects-section .verified{margin-left: 5px;background-color: #ddffe3; padding: 2px 11px; color: #1f5f2e;}
.inner-page .orange-bg-img.lg .heading{font-size:60px;}
.inner-page .small-heading{color:#6E939C; font-size:25px; line-height:1.2; text-transform: capitalize;}

body{color:#434343; margin:0; padding:0; font-family:'Jost', sans-serif, tahoma, arial; font-size:16px; overflow-x:hidden;}
.ant-layout{background-color:#F3F6F9;}
.blue-bg{background-color:#7303c1; color:#fff;}
.pink-bg{background-color:#ec38bd; color:#fff;}
.mat-ico{color:#F07E22; font-family:'Material Icons'; font-weight:normal; font-style:normal; font-size:18px; display:inline-block; line-height:1; text-transform:none; letter-spacing:normal; word-wrap:normal; white-space:nowrap; direction:ltr; -webkit-font-smoothing:antialiased; text-rendering:optimizeLegibility; -moz-osx-font-smoothing:grayscale; font-feature-settings:'liga';}
.stats-list .mat-ico{font-size: 16px;}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height:43px; border:1px solid #d9d9d9}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height:43px}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{height:43px}

.ant-picker,
input.ant-input-number-input{height:43px; border:1px solid #577c97; background-color:#f8f8f8;}

.ant-form-item-control-input{min-height:43px;}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{min-height:43px;}

.section-heading{color:#003965; margin-bottom:20px; font-size:20px}

.custom-form-block{padding-bottom:30px;}
.custom-form-block input,
.custom-form-block select{color:#333; padding:5px 10px;}

.custom-form-block .buttons .btn{margin-left:20px;}

.login-main-page{background-image:url(../images/login-bg.png); padding-top:0;     background-position: -219px -39px; background-repeat: no-repeat; background-color: #fff; min-height: 87vh; background-size: 55%;}
.signup-main-page{background-image:url(../images/signup-bg.png); padding-top:0; background-position:-200px -50px; background-repeat:no-repeat; background-color:#fff;}

.login-main-page .auth-block{margin-right:50px; margin-bottom:50px}
.signup-main-page .auth-block{width:50%}
.auth-block{width:45%; margin-top:60px; margin-bottom:60px}
.auth-block .section-heading{font-size:31px; font-weight: 600; margin-bottom: 10px;}

.auth-block .ant-input, .auth-block .ant-input-number-input{padding:10px 11px; }
.auth-block .ant-input-affix-wrapper{padding:10px 11px}
.auth-block .ant-input-number-input{height:42px;}
.remember-forgot > a{font-weight: 400; color: #962c08;}
.remember-forgot .ant-form-item-control-input{min-height: fit-content;}
.register-page .ant-form-item{margin-bottom: 15px;}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {border-color: #003965; border-left-width: 1px ;}

.ant-carousel{width:100%;}
.ant-carousel .slick-slide img{width:100%;}

.custom-carousel .slick-slide{margin:0px auto; width:calc(100% - 40px);}
.custom-carousel .slick-slide > div{padding:10px;}
.freelancers-profile .custom-carousel .slick-slide > div{padding:0px;}
.freelancers-profile .portfolio-tab.custom-carousel .block{padding:0;}
.portfolio-tab .blocks{display: flex; flex-wrap: wrap; gap: 10px;}
.portfolio-tab .blocks .row-portfolio-slider{width: 49%;}
.portfolio-tab .row-portfolio-slider .ant-tag {font-size: 13px; padding: 3px 9px;}
.products-offers-tab .single-offer{ margin-bottom: 14px; margin-right: 19px; padding: 0;}
.anticon svg{width: 0.8em !important;}
.about-tab .custom-carousel .block{padding:20px; }
.custom-carousel .block{border-radius:8px; margin:0px; padding:25px 25px 25px; border: solid 1px #E3E3E3;}
.custom-carousel .block .icon-block{width:50px; position:relative}
.custom-carousel .block .icon-block span{position:absolute; top:22px; left:calc(50% - 5px); width:100%; color:rgba(255,255,255,.8); font-weight:bold}
.about-tab .custom-carousel .block .text-block{padding:0; margin-top: 10px; margin-left: 12px;}
.custom-carousel .block .text-block{flex:1; padding: 10px 20px 20px; margin-top: 22px;}
.custom-carousel .block .sl-buttons{display:flex; justify-content:space-between; align-items:flex-start;}
.custom-carousel .block .sl-buttons .pointer{align-self:flex-start; font-size:12px; color:#003965; margin-right:10px; position:relative;}
.custom-carousel .block .sl-buttons .pointer:last-child{margin-right:0; margin-left:10px}
.custom-carousel .block .sl-buttons .pointer:first-child:after{content:''; display:block; width:1px; height:12px; background-color:#003965; position:absolute; right:-10px; top:3px;}
.custom-carousel .block{color:#787878; font-size:16px;}
.custom-carousel .block .sl-title{color:#003965; font-weight:600;}
.custom-carousel .block .sl-company-name{margin-bottom:10px; font-size:15px;}
.custom-carousel .block .sl-from-to{color:#003965; font-size:14px}
.custom-carousel .block .sl-text{font-size:15px; opacity: 0.8; line-height: 1.3;}
.custom-carousel .block .sl-tags{margin-top:10px;}
.custom-carousel .block .sl-tags .heading{color:#003965; margin-right:10px; font-size:14px !important;}
.custom-carousel .block .sl-tags .sl-single-tag{color:#6E939C; display:inline-block; font-size:14px; margin-right:15px; border:0 none; padding:0}
.sl-single-tag{border:1px solid #ee7d22; color:#ee7d22; border-radius:16px; display:inline-block; font-size:12px; margin-right:15px; padding:5px 15px;}

.custom-carousel .ant-carousel .slick-dots{bottom:40px;}
.custom-carousel .ant-carousel .slick-dots li button{background:#c9c9c9; opacity:1; border-radius:50%; height:6px; width:6px;}
.freelancers-profile .custom-carousel .ant-carousel .slick-dots li button{height:10px; width:10px;}
.ant-carousel .slick-dots li button, .ant-carousel .slick-dots li.slick-active button{background-color:#414141;}
.freelancers-profile  .row-portfolio-slider .block .img-block .slick-dots-bottom{bottom: -10px;}

.row-portfolio-slider{margin-bottom:30px}
.row-portfolio-slider .block{display:flex; justify-content:space-between;}
.row-portfolio-slider .block .img-block{width:100%;}
.row-portfolio-slider .block .sl-title{ font-size: 18px;}
.update-profile-page .ant-tabs-ink-bar{display:none !important;}

.close-danger{display:flex; align-items:center; justify-content:center; width:20px; height:20px; cursor:pointer; border-radius:50%; transition:.2s all ease-in-out; background-color:rgb(255, 69, 0, .3);}
.close-danger:hover{background-color:rgb(255, 69, 0, .4);}
.close-danger svg{color:rgba(0, 0, 0, .5)}

.custom-carousel .row-portfolio-slider .sl-tags .sl-single-tag{border:1px solid #A24921; padding:5px 10px; color:#A24921; border-radius:5px}

.row-portfolio-slider .block .img-block .slick-dots-bottom{bottom:0}
.slider-inner .ant-row{justify-content:space-between}
.slider-inner .ant-row .ant-col-12{max-width:48%; margin-bottom:30px}

.portfolio-main-image-box{flex:1; height:120px; background-color:rgba(0,0,0,.01); border:1px solid rgba(0,0,0,.1); justify-content:center; align-items:center; display:flex; cursor:pointer;}
.portfolio-main-image-box .feather{font-size:50px; opacity:.6; width:auto}
.portfolio-image-preview{width:100%; height:100%; background-position:center; background-repeat:no-repeat; background-size:contain;}

.portfolio-image{width:100%; height:240px; background-color:rgba(0,0,0,.01); border:1px solid rgba(0,0,0,.1); background-size:contain; background-position:center; background-repeat:no-repeat;}

.post-project-steps{background-color:#fff; border-radius:50px}
.ant-steps.post-project-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item{padding-top:0; width:33.3%}
.post-project-steps .ant-steps-item-title::after{display:none}
.post-project-steps .ant-steps-item-icon{display:none}

.post-project-steps .ant-steps-item .ant-steps-item-container{border-radius:35px; color:#fff; padding:15px; display:flex; justify-content:center; align-items:center;}
.post-project-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{color:rgba(0, 0, 0, 0.85); font-weight:bold}
.post-project-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{padding-left:0}

.post-project-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-container{background-color:#003965; border-radius:0; color:#fff; padding:15px; display:flex; justify-content:center; align-items:center;}
.post-project-steps .ant-steps-item-finish:first-child .ant-steps-item-container{border-radius:35px 0 0 35px; border-right:1px solid #fff}
.post-project-steps .ant-steps-item-finish:last-child .ant-steps-item-container{border-radius:0 35px 35px 0; border-left:1px solid #fff}
.post-project-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-title{color:#fff; font-weight:bold;}

.form-gallery{position:relative;}
.form-gallery ul{display:flex; justify-content:flex-start; align-items:flex-start; flex-wrap:wrap; padding:0}
.form-gallery ul li{width:100px; height:100px; margin-right:20px; margin-bottom:20px; border:1px solid rgba(0,0,0,.1); padding:5px; align-items:center; justify-content:center; display:flex; cursor:pointer; position:relative;}
.form-gallery ul .preview-image{max-width:100%; max-height:100%;}
.form-gallery ul li .close{cursor:pointer; position:absolute; top:0; right:0; background-color:#ff4500; border:0 none; padding:0; margin:0; color:#fff; border-radius:50%; width:20px; height:20px; display:flex; justify-content:center; align-items:center; opacity:0; transition:.2s all ease-in-out;}
.form-gallery ul li .close .feather{margin:0}
.form-gallery ul li:hover .close{opacity:1;}
.form-gallery.lg ul li{width:200px; height:200px}
.form-gallery.sm ul li{width:80px; height:80px}

.file-gallery-preview{height:100%; display:flex; align-items:center; justify-content:center;}
.file-gallery-preview .file-icon{font-size:50px; color:#7303c1}

.form-gallery-container{display:flex; justify-content:flex-start; align-items:center; margin-bottom:40px;}
.gallery-increment{display:flex; justify-content:center; align-items:center; cursor:pointer; width:100px; height:100px; flex-direction:column;}
.gallery-increment p{margin:0; text-transform:none}
.gallery-increment .feather{margin:0}

.ant-layout-header{background-color:#fff; border-bottom:1px solid #ccc; display:flex; flex-direction:column; height:98px; line-height:initial; justify-content:center; padding:0; z-index: 9999;}
.header-block{display:flex; justify-content:space-between; position:relative;}
.header-block .first-block{display:flex;}
.header-block .second-block{align-self:flex-end;}
.header-block .third-block{display:flex; flex-direction:column; justify-content:center;}
.navbar-form span:first-child{color:#828282;}
.navigation{align-self:center; margin-right:40px; margin-top:16px; width:24px;}
.navigation .nav-lines{cursor:pointer; display:flex; flex-direction:column; height:16px; justify-content:space-between;}
.navigation .line{background-color:#4e413b; height:3px; width:100%;}
.mega-menu{display:none; padding-top:33px; position:absolute; width:100%; z-index:9999;}

.mega-menu .bg-white{width:100%;}

.mega-menu.show{display:block;}

@media only screen and (min-width:1024px){
.navigation:hover .mega-menu{display:block;}
.mega-menu .menu > li:hover .sub-menu-wrapper{display:block}
}

.mega-menu .menu{background-color:#4e413b; padding-top:20px; position:relative; width:25%;}
.nav-link{padding:0px;}
.mega-menu .menu a{color:#4e413b; display:block; padding:15px 40px; text-decoration:none; width:100%;}
.mega-menu .menu .sub-menu{background-color:#eaeaea; display:flex; padding:20px; flex-wrap:wrap; opacity:0; transition:.2s all ease-in-out;}
.mega-menu .menu .sub-menu .sub-menu-2{background-color:#fff; display:none; left:100%; padding-top:20px; position:absolute; top:0px; width:100%;}
.mega-menu .menu > li.nav-link > a{color:#f3f6f9;}
.mega-menu .menu > li.nav-link > a:hover{background-color:#f8f8f8; color:inherit;}
.mega-menu .menu > li:hover .sub-menu{opacity:1;}
.mega-menu .sub-menu > li > a:hover{background-color:#fff;}

.sub-menu-wrapper{background-color:#eaeaea; height:100%; width:calc(1240px - 310px); position:absolute; left:100%; top:0; display:none}
.sub-menu-wrapper:hover ~ a{background-color:#fff;}

.mega-menu .menu .sub-menu .nav-link{width:30%}

.mega-menu .sub-menu > li:hover .sub-menu-2{display:block;}
.mega-menu .sub-menu-2 > li > a:hover{background-color:#f8f8f8;}

.ant-menu-horizontal > .ant-menu-item a:hover{color:#fff}
.ant-menu-horizontal > .ant-menu-item:hover:after{content:''; display:none}

.single-project div.ant-typography{ color: #434343; opacity: 0.8; font-size: 15px; min-height: 71px;}
.projects-search-section div.ant-typography{ color: #434343; opacity: 0.8; font-size: 15px;}

header .links{display:flex;}
header .links a,
header .links .btn{border:0px none; background-color:transparent; color:#707070; font-size:16px; padding:12px 20px;}
header .links .btn{margin-left:15px;}
header .links .btn-fill{background-color:#962c08; color:#fff; margin:0; display:block}
header .ant-layout-header .ant-menu{border:0px none;}

header .ant-menu{border-bottom:0px none;}
header .ant-menu > li{border:0px none !important; margin-left:15px !important; margin-right:0px !important;}
header .ant-menu-submenu > .ant-menu-submenu-title{color:#707070; font-size:16px; padding:7px 20px !important;}
header .ant-menu-submenu:hover:after,
header .ant-menu-submenu:after{border:0 none !important}

.ant-avatar-image{}
.ant-avatar-image.empty{background-color:#d1ccca; padding:20px; border-radius:50%;}
.ant-avatar-image.empty > img{object-fit: contain !important;}

.navbar-form{box-shadow: 0px 4px 9px #fbfbfb; display:flex; justify-content:center; margin-bottom:0px; border: solid 1px #f4f0f0; border-radius: 5px;}
.navbar-form input:focus-visible, .ant-select:focus-visible{outline: none!important;}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{border: none; box-shadow: none;}
.navbar-form .form-control{margin-bottom:0px;}
.navbar-form span{align-self:center;}
.navbar-form input{border:0px none; border-radius:0px; border-right:1px solid #cfcfcf; color:#828282; font-size:13px; font-weight:300; max-width:150px; padding:10px 25px;}
.navbar-form span.block:nth-child(2){padding:4px 0;}
.navbar-form span:nth-child(2) .form-control{color:#3e3e3e; font-size:13px; font-weight:300; padding:0px;}
.navbar-form span:nth-child(2) .form-control,
.navbar-form span:nth-child(2) .form-control:active,
.navbar-form span:nth-child(2) .form-control:focus{border:0px none;}
.navbar-form span:nth-child(2) .form-control .ant-select-selector{border:0px none; font-size:15px;}
.navbar-form button.icon{background-color:#ee7d22; border:0px none; display:flex; flex-direction:column; justify-content:center; text-align:center; padding:5px 10px; border-radius:0 5px 5px 0; cursor:pointer}
.navbar-form button.icon .mat-ico{color:#fff; font-size:16px;}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:inherit; z-index: 99;}

.manage-projects-list .ant-tabs-tab, .projects-as-seller .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab{padding: 5px 20px 5px 0 !important;}
.manage-projects-list .ant-tabs-tab-btn a, .projects-as-seller .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-btn a{color: #434343;}
.manage-projects-list .ant-tabs-tab-active .ant-tabs-tab-btn a, .projects-as-seller .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn a{color: #222; font-weight: 600;}
.manage-projects-list .tab-heading, .projects-as-seller .tab-heading{color: #434343; font-weight: 600;}
.manage-projects-list .ant-table-thead > tr > th, .projects-as-seller  .ant-table-thead > tr > th, .products-list .ant-table-thead > tr > th{background-color: #E1E8EF;}
.ant-tabs-top .ant-tabs-tab {padding: 8px 17px;}
.ant-tabs-top .ant-tabs-tab a{color: #1b1b1b; opacity: 0.7;}
.ant-tabs-top .ant-tabs-tab a:hover{opacity: 1;}
.ant-tabs-top .ant-tabs-tab-active a{color: #fff; opacity: 1;}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{border: none; background-color: transparent;}
.ant-pagination-item-link .anticon svg{width:3em !important;}
.ant-table-pagination.ant-pagination .ant-pagination-item-active a{background-color: #828282 !important;}
.ant-pagination-item-active{background-color: transparent;}

.page-static-banner .anticon.anticon-left{justify-content: flex-start;}


.ant-layout-footer{background-color:#4e413b; padding:0px;}
.ant-layout-footer .top-block{padding:70px 0 50px;}
.ant-layout-footer .input-box input{background-color:#e9eaeb; border:0px solid; border-radius:44px; height:45px; padding:0px 25px; width:730px;}
.ant-layout-footer .footer-block{display:flex; flex-wrap:wrap; justify-content:space-between;}
.ant-layout-footer .footer-block .block{padding:0px 15px; width:23%;}
.ant-layout-footer .footer-block .block:first-child{padding-left:0px;}
.ant-layout-footer .footer-block .block:last-child{padding-right:0px;}
.ant-layout-footer .block .heading{font-size:20px; color:#fff; margin:15px 0px;}
.ant-layout-footer .block ul li a {font-size: 14.5px !important; padding: 3px 0 !important;}
.ant-layout-footer .block ul li a:hover {color: #FC9E00;}

.ant-layout-footer .block ul li{margin-bottom:6px;}
.ant-layout-footer .block ul li a{color:#fff; display:block; font-size:14px; padding:5px 0;}

.ant-layout-footer .copyright{border-top:1px solid #828282; color:#828282; font-size:16px; font-family:Nunito; margin-top:20px; padding:35px 0;}
.ant-layout-footer .copyright .block{justify-content:space-between;}
.ant-layout-footer .copyright .left{align-items:center; display:flex;}
.ant-layout-footer .copyright ul{justify-content:space-between; margin-bottom:0px;}
.ant-layout-footer .copyright ul li{margin-bottom:0px;}
/* .ant-layout-footer .copyright ul li:not(:first-child){margin-left:15px;} */
.ant-layout-footer .copyright ul li a{align-items:center; display:flex; flex-direction:column; height:36px; padding:0px; text-align:center; width:36px;}
.ant-layout-footer .copyright ul li a:hover{border-color:#f07e22;}
.ant-layout-footer .copyright ul li a:hover .ico.facebook{background-position:-13px -38px; height:18px; width:10px;}
.ant-layout-footer .copyright ul li a:hover .ico.twitter{background-position:-42px -37px; height:18px; width:20px;}
.ant-layout-footer .copyright ul li a:hover .ico.linkedin{background-position:-78px -37px; height:18px; width:20px;}
.ant-layout-footer .copyright ul li a:hover .ico.youtube{background-position:-14px -288px; height:18px; width:24px;}

.homepage .ant-layout{background-color:#fff;}

.chip{background-color:#ee7d22; padding:5px 30px; border-radius:50px; font-weight:bold; color:#fff; display:block; transition:.2s all ease-out}
.chip:hover{background-color:#f58a31;}

.ant-carousel .slick-arrow{background-color:transparent; border:0px none; height:40px; font-size:0px; padding:0px; position:absolute; text-align:center; top:45%; width:40px;}
.ant-carousel .slick-prev{left:-40px;}
.ant-carousel .slick-next{right:-40px;}
.ant-carousel .slick-arrow::before{background-image:url('../images/icons.png'); background-repeat: no-repeat; display:inline-block; }
.ant-carousel .slick-arrow.slick-prev::before{background-position:-159px -8px; height:22px; width:13px;}
.ant-carousel .slick-arrow.slick-next::before{background-position:-192px -8px; height:22px; width:13px;}
.sp-projects-block .ant-tabs{overflow:revert;}

.banner-slider .banners-block{background-repeat:no-repeat; background-position:center center; position:relative; color:#fff; min-height:300px; width:100%;}
.banner-slider .bg-block{height:100%; width:100%; position:relative; z-index:1; min-height:300px;}
.banner-slider .bg-block img{min-height:300px;}

.banner-slider .head-text{font-size:60px; font-weight:700; line-height:1; margin-bottom: 15px; margin-top: 10px;}
.banner-slider .description{font-size:18px; margin-bottom:25px; line-height: 1.3; padding-right: 2em;}
.banner-slider .links a{font-size:16px; margin-right:12px;}

.banner-slider .banner-wrapper{width:100%; height:100%; position:absolute; z-index:999; top:0; display:flex; align-items:center; justify-content:flex-start;}
.banner-slider .block{margin-left:100px; display:flex; flex-direction:column; justify-content:flex-start;}
.homepage .banner-slider .block{max-width:700px;}
.banner-slider .block{max-width:42%;}
.banner-slider .block .chip{align-self:flex-start; position:relative; z-index:9999;}

.trusted-partners-section{padding-top:50px;}
.trusted-partners-section .container{display:flex; justify-content:space-between;}
.trusted-partners-section .left-block{width:230px;}
.trusted-partners-section .heading{line-height:1.2;}
.trusted-partners-section .right-block{width:100%}

.trusted-partners-listing .single{align-items:center; display:flex !important; flex-direction:column; height:auto; justify-content:center;}
.trusted-partners-listing .slick-arrow{top:40%;}

.categories-section{padding:45px 0 130px;}
.categories-section .heading{margin-bottom:50px;}
.category-block{box-shadow: -1px 6px 16px rgb(0 0 0 / 8%); margin-right:2%; transition:.2s all ease-in-out}
.category-block:nth-child(4n){margin-right:0}
.category-block .cat-heading,.category-block .hover-div .title {font-weight: 500; line-height: 1.2em; padding: 5px 0;}

.category-block .main{min-height:260px;}
.category-block .main img{margin:0 auto; width:100px}
.category-block .hover-div{background-color:#928a87; top:100%; transition:all 0.3s ease-in-out 0s; z-index:99; display: flex; flex-direction: column; justify-content: center;}
.category-block .mat-ico{align-self:center; color:#fff; margin-top:2px; transform:rotate(180deg);}
.category-block:hover .hover-div{top:0;}
.category-block:hover{box-shadow:0px 5px 15px rgba(0,0,0,.35)}

.img-text-section{display:flex;}
.img-text-section > div{width:50%;}
.img-text-section > div.img-block{display:flex; justify-content:flex-end;}
.img-text-section.rev-row > div.img-block{display:flex; justify-content:flex-start;}
.img-text-section .text-block .block{padding:85px 90px;}
.img-text-section .text-block .text{color:#434343; margin-bottom:20px; }
.img-text-section .current-block-categories{margin-top:35px;}
.img-text-section .current-block-categories .title{font-size:20px; font-weight:500; margin-bottom:10px; color: #000000}
.img-text-section .categories-list .btn-blank-brown{margin-bottom:8px; margin-right:10px;}
.current-block-categories .ant-btn-primary{border: solid 1px #962c08; padding: 6px 18px; height: 100%; font-weight: 400; border-radius: 3px; box-shadow: none;}
.current-block-categories .ant-btn-primary:hover{background-color: #962c0810; color: #962c08;}

.price-container .currency svg{transform: scale(0.8); fill: #003965;}
.price-container .currency{vertical-align: middle;}

.img-text-section .categories-list{display:flex; flex-wrap:wrap;}
.img-text-section .categories-list a{margin-bottom:10px; margin-right:10px;}

.img-text-section.discover-sp .text-block{border-top:1px solid #f0f0f0;}
.img-text-section.explore-offers .text-block{border-bottom:1px solid #f0f0f0;}

.projects-listing .row-item {padding: 30px !important;}
.projects-listing .slick-slide > div{margin:0 15px;}
.projects-listing .single-project{border:1px solid #bebebe; border-radius:5px; color:#003965; height:auto;}
.projects-listing .single-project:hover{border-color:#f07e22; box-shadow:0 0 20px #eee;}
.projects-listing .single-project .top-block{border-bottom:1px solid #c7c7c7; padding:26px;}
.projects-listing .title{font-size:22px; font-weight:500;}
.projects-listing .added-on{font-weight:400; margin-bottom:14px; opacity: 0.8;}
.projects-listing .tags{display:flex; flex-wrap:wrap; justify-content:flex-start; margin-bottom:12px;}
.projects-listing .tag, .projects-search-section .mid-section .ant-tag{background-color:#962c08; border-radius:3px; color:#fff; font-size:15px; font-weight:500; padding:1px 12px; border: none;}
.projects-listing .text{font-size:15px; margin-bottom:18px; opacity:0.5; color: #000;}
.projects-listing .links{font-size:15px; font-weight:500;}
.projects-listing .links > span{margin-right:25px;}
.projects-listing .links .ico{margin-right:5px;}
.projects-listing .single-project .bottom-block, .products-offers-tab .single-offer .bottom-block{display:flex; justify-content:space-between; padding:11px 25px;}
.projects-listing .single-project .price-block, .products-offers-tab .single-offer .price-block{align-self:center; font-size: 21px; font-weight: 500; text-transform: uppercase;}
.projects-listing .single-project .bottom-block a.btn, .products-offers-tab .single-offer .bottom-block a.btn{height: 100%; padding: 7px 20px; background-color: #a24921; color:#fff; border-radius: 20px;}
.projects-listing .single-project .bottom-block a.btn:hover{background-color: #962c08;}
.projects-listing .anticon svg, .products-listing .anticon svg, .profile-details .anticon svg {width: 0.8em !important;}
/* .projects-listing .row-item .left, .projects-listing .row-item .right{min-height: 190px;} */
.projects-listing .row-item .right .rating_box{gap: 10px;}
.projects-listing .row-item .right .rating_box div, .freelancers-search-section .rating_box div, .post-project .rating_box div{align-self: center;}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{padding: 0;}


.homepage .sp-projects-block{padding:3.5em 0;}
.sp-projects-block{padding:1.5em 0;}
.sp-projects-block .ant-tabs-nav-list{ border-bottom: solid 1px #00396563; margin-bottom: 25px;}
.sp-projects-block .ant-tabs-tab.ant-tabs-tab-active{color: #962c08; font-weight: 500; border-bottom: solid 3px #962c08; text-shadow: none;}
.sp-projects-block .ant-tabs-tab {padding: 8px;  color: #003965; text-shadow: none;}
.sp-projects-block .ant-tabs-bar{border-bottom:0px none; margin-bottom:60px;}
.sp-projects-block .ant-tabs-top .ant-tabs-ink-bar-animated{display:none !important;}
.sp-projects-block .ant-tabs-nav-scroll{text-align:center;}
.sp-projects-block .ant-tabs-nav-scroll > div{margin:0 auto;}

.category .sp-projects-block{padding-top:0}

.sp-list-block{display:flex; justify-content:center; margin-top:10px; padding:25px 0; box-shadow: 0 15px 25px #ececec; border-radius: 10px;}
.sp-list-block > div{color:#003965; display:flex; flex-direction:column; justify-content:center; text-align:center;  width:33.33%;}
.sp-list-block > div:not(:last-child){border-right:1px solid #d1d1d1;}
.sp-list-block > div:hover{color:#962c08;}
.sp-list-block .num{font-size:35px; font-weight:500;}
.sp-list-block .text{font-size:16px;}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{padding:0 20px;}
.ant-select-single.ant-select-open:not(.ant-select-customize-input) .ant-select-selector{border-color:#000;}

.orange-bg-img{background-size:cover; min-height:136px;}
.orange-bg-img.lg{min-height:510px;}
.banner-block{display:flex; flex-direction:column; justify-content:center; min-height:136px; position:relative; background-color:#a34921;}
.banner-block > img{height:100%; position:absolute; z-index:1;}
.banner-block .container{color:#fff; line-height:1.2; position:relative; z-index:1;}

.ant-tabs-tab:hover{color:#003965;}

.search-row .ant-select-selector{border:0px none !important;}
.search-row .ant-input-search-button{background-color:#F07E22; border:0px none; border-radius:50% !important; height:40px; margin-left:-20px; padding:0px; width:40px; z-index:1;}
.listing-section.white-bg,
.right-section.white-bg{padding:30px 20px;}
.search-count{color:#003965; font-size:25px; font-weight:600;}
.projects-search .ant-select-item{font-size:16px; padding:0 10px;}
.ant-select-item .ant-select-item-option-content{border-radius:5px; color:#000; padding:5px;}

.ant-select-item,
.ant-select-item-option-active,
.ant-select-item-option-selected,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color:transparent;}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) .ant-select-item-option-content,
.ant-select-item-option-selected .ant-select-item-option-content{background-color:rgba(0, 0, 0, .1);}

.listing-section .listing{margin:0 0 50px;}
.listing-section .top-filters{margin-bottom:20px;}
.listing-section .single-item{border:1px solid #B5B5C3; padding:20px;}
.listing-section .single-item:not(:last-child){margin-bottom:20px;}
.listing-section .single-item:hover{box-shadow:0 0 15px #eee;}
.listing-section .single-item .left{margin-right:25px}
.listing-section .single-item .top-section{color:#003965;}
.listing-section .single-item .title{font-size:20px; font-weight:600; max-width:70%;}
.listing-section .single-item .price{font-size:24px; font-weight:bold; line-height:1.4;}
.listing-section .single-item .type{font-size:10.5px; text-align:right;}
.listing-section .single-item .mid-section{max-width:70%;}
.listing-section .single-item .mid-section .tags{margin-bottom:15px;}
.listing-section .single-item .mid-section .tags .ant-tag{background-color:#962C08; border:0px none; border-radius:5px; color:#fff; font-size:15px; font-weight:500; padding:5px 10px;}
.freelancer-profile-page .ant-tag, .proposal-overview .ant-tag{color:#a24921; background-color: #fff; border:solid 1px #a2492169; border-radius:3px; font-size:15px; font-weight:400; padding:5px 10px; white-space: break-spaces; margin-bottom: 6px;}
.listing-section .single-item .mid-section .description{color:#6E939C;}
.listing-section .single-item .bottom-section{display:flex; justify-content:space-between;}

.ant-pagination.mini .ant-pagination-item{position:relative;}
.ant-pagination.mini .ant-pagination-item-active{border:0px none;}
.ant-pagination.mini .ant-pagination-item-active::after,
.ant-pagination.mini .ant-pagination-item:hover::after{content:''; display:block; position:absolute; height:1px; width:10px; background-color:#003965; bottom:0px; left:37%;}
.ant-pagination.mini a{color:#6E939C; font-size:19px; font-weight:500; padding:0 10px;}
.ant-pagination.mini .ant-pagination-prev a,
.ant-pagination.mini .ant-pagination-next a{font-size:15px;}
.ant-pagination.mini .ant-pagination-item-active a,
.ant-pagination.mini .ant-pagination-item:hover a{color:#003965;}

.filters .main-title{color:#003965; font-size:20px; font-weight:600; margin-bottom:15px;}
.filters .filter-heading{color:#6E939C; font-size:17px; font-weight:500; margin-bottom: 7px;}
.filters .filter-list{margin-bottom:30px;}
.filters .filter-list .single{display:block; max-height:150px; overflow:auto;}
.filters .filter-list .ant-checkbox-wrapper{color:#003965; font-weight:500; margin-bottom:6px; margin-left:0px; width:100%;}
form label, #projects_filter label, #products_filter label {font-size: 14px !important;}
.filters .filter-list .ant-checkbox + span{display:flex; float:right; justify-content: space-between; width:calc(100% - 20px);}
.filters .filter-list .ant-checkbox-wrapper .num{color:#6E939C;}
.filters .filter-list .dull{color:#6E939C; font-weight:500; margin-right:8px;}
.filters .filter-list .colored{color:#003965; font-weight:500; margin-left:8px;}
.ant-switch-small{height:18px; min-width:40px;}
.ant-switch-small::after{height:14px; width:14px;}
.ant-switch-checked{background-color:#003965;}
.ant-switch-small .ant-switch-handle{top:3px; left:3px;}

.freelancers-search-section .ant-btn-submit, .projects-search-section .ant-btn-submit{background-color: #003965; color: #fff; padding: 10px 20px; height: 100%;}
.freelancers-search-section .ant-btn-button.outlined, .projects-search-section .ant-btn-button.outlined{border: solid 1px #928a87; padding: 10px 20px; height: 100%;}
.freelancers-search-section .ant-btn-button.outlined:hover, .projects-search-section .ant-btn-button.outlined:hover{color: #000; background-color: #f4f5fb;}
.freelancers-search-section .ant-btn-submit:hover, .projects-search-section .ant-btn-submit:hover{background-color: #0a4c7e;}

.ant-divider-horizontal.ant-divider-with-text{color:#003965; font-weight:600; font-size:18px;}
.ant-slider-handle{border:1px solid #707070; height:16px; margin-top:-6px; width:16px;}
.ant-slider-track,
.ant-slider:hover .ant-slider-track{background-color:#F07E22;}
.filters .button{display:flex; justify-content:flex-end;}

.hidden{display:none !important}

.profile-pic-box{margin-bottom:30px}
.profile-pic-box .picture{display:flex; flex-direction: column; justify-content:flex-start; row-gap: 10px;}
.profile-main-wrapper .ant-form label {color:#375c80 !important; margin-right:10px; font-weight: 600;}
.profile-main-wrapper .ant-form label {color:#375c80 !important; margin-right:10px; font-weight: 600;}
.profile-pic-box .picture .profile-pic-bg{width:80px; height:80px; background-color:#fff; display:flex; justify-content:center; align-items:center; box-shadow:0 2px 5px rgba(0,0,0,.1); border-radius:5px;}
.profile-pic-box .picture .profile-pic-banner-bg{width:100%; height:100px; background-color:#fff; display:flex; justify-content:center; align-items:center; box-shadow:0 2px 5px rgba(0,0,0,.1); border-radius:5px;}

.profile-pic-box .profile-pic-sm{width:100%; height:100%; background-size:100%; background-position:center center; background-repeat:no-repeat}
.profile-pic-box .profile-banner-preview{width:100%; height:100%; background-size:100%; background-position:center}

.profile-pic-box.type-input .picture{flex-direction:column; align-items:flex-start}
.profile-pic-box.type-input .picture > label{margin-bottom:20px}
.profile-pic-box.type-input .picture .profile-pic-banner-bg{align-items:center; justify-content:flex-start; padding-left:35px;}
.profile-pic-box.type-input .picture .profile-pic-banner-bg.solid{background-color:#f8f8f8;}
.profile-pic-box.type-input .picture .profile-pic-banner-bg .feather{width:50px; height:50px; color:rgba(0, 57, 101, .4)}
.profile-pic-box.type-input .picture .profile-pic-banner-bg p{margin:0 0 0 20px; color:#003965; font-size:14px;}

.search-block{width:100%; display:flex; justify-content:center; align-items:center; margin-bottom:10px; margin-top:30px}
.search-block .search-row{width:100%;}
.search-block .ant-select{width:100%;}
.listing-page .ant-select-selector{background-color:#fff !important; font-size:16px; height:56px !important;}
.sort-by-filter .ant-select-selector{height:44px !important;}
.listing-page .ant-select-selector .ant-select-selection-item,
.listing-page .ant-select-selector .ant-select-selection-placeholder{line-height:54px !important;}
.sort-by-filter .ant-select-selector .ant-select-selection-item{line-height:42px !important;}
.search-block input{border:1px solid #828282; border-radius:0px; border-top-right-radius:28px; border-bottom-right-radius:28px; font-size:16px; height:56px; padding:18px 64px 18px 18px; width:100%;}
.search-block .search-input{flex:1 1}
.search-block .search-input .ant-select{}
.search-block .search-input input{border:0 none; padding:10px; width:100%;}
.search-block .search-icon{opacity:.4; margin-left:20px; margin-right:20px;}
.search-block .search-button{background-color:#f07e22; border:0 none; padding:12px; border-radius:50%; height:56px; position:absolute; right:0px; width:56px;}
.search-block .search-button .search-btn-icon{margin:0; color:#fff}

.ant-list-pagination{text-align:center;}

.ant-rate-star.ant-rate-star-full path{color: #F07E22;}
.ant-rate.sm li{margin-right:3px;}
.ant-rate.sm li svg{font-size:12px;}
.ant-rate-star{margin-right: 3px !important;}
.projects-listing .tags{margin-top: 5px;}
.projects-list .tags .ant-tag, .freelancers-search-section .tags .ant-tag, .post-project .tags .ant-tag{background-color: #928A87; padding: 3px 10px; margin-right: 5px; margin-bottom: 5px; color: #fff; border-radius: 5px; font-size: 12px;}
.total-other {align-self: center;}
.products-listing .ant-rate{align-self: center; display: flex !important;}

.freelancers-search .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{background-color:#F4F4F4; border:0px none;}

.row-item{border-radius:10px; border:1px solid rgba(0, 0, 0, .2); align-items:flex-start; justify-content:space-between; padding:20px; background-color:#fff; display:flex;}

.listing-section .top-filters{margin-bottom:20px;}
.row-item{border:1px solid #B5B5C3; padding:20px;}
.row-item:not(:last-child){margin-bottom:20px;}
.row-item:hover{box-shadow: 0px 7px 14px 0px #dadada;}

.row-item{border-radius:10px; border:1px solid #B5B5C3; justify-content:space-between; }
.row-item .left{width:102px;}
.row-item .right{width:calc(100% - 110px);}
.post-project .row-item .right{width:30%;}
.post-project .row-item div:nth-child(3){width:40%; margin-top: 0 !important;}
.post-project .row-item div:nth-child(4){width:20% !important; margin-top: 0 !important; flex-direction: column-reverse; align-items: flex-end !important; gap: 5px !important;}
.row-item .profile-image{position:relative; text-align: center;}
.row-item .profile-image .featured-text{background-color:#962C08; color:#fff; font-size:15px; font-weight:500; position:absolute; left:-20px; top:0px; padding:5px 12px; border-top-right-radius:5px; border-bottom-right-radius:5px; box-shadow:0 0 10px rgba(255,255,255, .16);}

.listing .verified{color:#52C619; font-weight:600; font-size:13px; margin-left:20px;}
.freelancers-search .featured .ant-tag{background-color:#962C08; font-size:15px; border:0px; border-radius:5px; color:#fff; margin-left:20px; padding:5px 15px;}
.related-projects-listing .tags .ant-tag{background-color:#962C08; font-size:13px; border:0; border-radius:5px 0 5px 0; color:#fff; padding:3px 13px; position: absolute; left: 0px; top: 0;}
.related-projects-listing .row-item{padding: 30px;}
.related-projects-listing .top-section{min-height: 50px; flex-direction: column; align-items: flex-start !important;}
.related-projects-listing .top-section .price-section{flex-direction: row; gap: 5px;}
.related-projects-listing .top-section .price-section .price{color: #962C08 !important;}
.related-projects-listing .bottom-section .details div.w-25{width: 50%;}

.freelancers-profile-left-section{border-top:4px solid #FC9E0050}
.freelancers-profile-right-section .ant-tabs-nav-wrap{justify-content: left !important;}

.blank-blue-btn{border:1px solid #003965 !important; border-radius:5px; color:#003965; font-size:16px; height:auto; padding:5px 20px;}
.blank-blue-btn:hover{background-color:#003965; color:#fff}

.blog-listing-section.inner-page .listing-section .heading{color:#003965; font-size:30px; font-weight:600; margin-bottom:40px;}
.blog-listing-section .right-section .text{color:#003965; font-size:20px; font-weight:600;}

/* .blog-listing-section .social-links-text{margin-bottom:30px;}
.blog-listing-section ul.social-links{justify-content:space-between; margin-bottom:0px;}
.blog-listing-section ul.social-links li{margin-bottom:0px; margin-left:10px;}
.blog-listing-section ul.social-links li a{align-items:center; border-radius:50%; border:1px solid #828282; display:flex; flex-direction:column; height:34px; justify-content:center; padding:0px; text-align:center; width:34px;}
.blog-listing-section ul.social-links li a:hover{border-color:#f07e22;}
.blog-listing-section ul.social-links li a:hover .ico.facebook{background-position:-13px -38px; height:18px; width:10px;}
.blog-listing-section ul.social-links li a:hover .ico.twitter{background-position:-42px -37px; height:18px; width:20px;}
.blog-listing-section ul.social-links li a:hover .ico.linkedin{background-position:-78px -37px; height:18px; width:20px;}

.blog-listing-section .single-item{border-radius:5px; border-color:#DEDEDE; justify-content:space-between; overflow:hidden; padding:0px;}
.blog-listing-section .single-item .left{width:256px;}
.blog-listing-section .single-item .right{padding:20px; width:calc(100% - 256px);}
.blog-listing-section .profile-block{display:flex; margin-bottom:14px;}
.blog-listing-section .profile-block img{border-radius:5px; margin-right:15px; max-height:52px; max-width:53px;}
.blog-listing-section .profile-detail{display:flex; flex-direction:column;}
.blog-listing-section .profile-block .name{color:#003965; font-size:18px; font-weight:600;}
.blog-listing-section .profile-block .category-name{color:#6E939C; font-size:16px;}
.blog-listing-section .single-item .title{color:#003965; margin-bottom:10px; max-width:100%;}
.blog-listing-section .single-item .description{color:#6E939C}
.blog-listing-section .single-item .bottom-section{align-items:center; display:flex; margin-top:20px;}
.blog-listing-section .single-item .comment-block{color:#6E939C; display:flex; font-size:16px; font-weight:500;}
.blog-listing-section .single-item .comment-block .mat-ico{margin-right:5px;}

.blog-listing-section .search-row input{border-color:#828282;}
.blog-listing-section .ant-collapse{background-color:transparent; border-bottom:1px solid; border-color:#EDEDED; border-radius:5px; font-size:16px; font-weight:600; margin-bottom:10px; overflow:hidden;}
.blog-listing-section .ant-collapse-header{background-color:#F8F8F8; color:#003965;}
.blog-listing-section .ant-collapse-item-active .ant-collapse-header{background-color:#A24921; color:#fff;}
.blog-listing-section .ant-collapse > .ant-collapse-item{border-bottom-color:#EDEDED;}
.blog-listing-section .ant-collapse > .ant-collapse-item:last-child{border-bottom-color:transparent;}
.blog-listing-section .ant-checkbox-wrapper{color:#222; font-weight:400; margin-left:0px; width:100%;}
.blog-listing-section .ant-checkbox-wrapper:not(:last-child){margin-bottom:12px;}
.blog-listing-section .ant-checkbox{margin-right:20px;}

.blog-listing-section .other-posts-block{margin-top:40px;}
.other-posts-block .heading{color:#A24921; font-size:20px; margin-bottom:30px;}
.other-posts .single-item{border:1px solid #D3D3D3; border-radius:5px; line-height:1.4; padding:20px 12px;}
.other-posts .single-item:not(:last-child){margin-bottom:20px;}
.other-posts .single-item:hover{box-shadow:0 0 10px rgba(131, 131, 131, .16);}
.other-posts .single-item .title{color:#003965; font-size:16px;}
.other-posts .single-item .desc{color:#6E939C;}
.other-posts .single-item .link{color:#962C08; display:inline-block; font-size:16px; font-weight:500; margin-top:12px;} */

.post-project-page form label.upload-panel{background-color:#fff; border:1px dashed rgba(0,0,0,.1); display:flex; justify-content:center; flex-direction:column; align-items:center; padding:30px; cursor:pointer; transition:.2s all ease-in-out}
.post-project-page form label.upload-panel:hover{background-color:rgba(115, 3, 192, .03); border-color:rgba(115, 3, 192, .3)}
.post-project-page form label.upload-panel .icon{font-size:50px; color:#ee7d22}
.post-project-page form label.upload-panel p{margin:0; font-weight:normal; text-transform:capitalize; color:rgba(0,0,0,.5);}


.freelancer-profile-page .main-content{padding-top:30px; background-color: #F3F6F9;}
.profile-bg-img .profile-banner{width:100%; height:350px; position:absolute; background-size:cover; background-position:center center; background-repeat:no-repeat;}
.profile-bg-img .profile-banner::before{position:absolute; content:''; display:block; width:100%; height:100%; background-color:rgba(0,0,0,.4); background: linear-gradient(0deg, rgba(24, 40, 56,0.95) 0%, rgba(0,0,0,0) 100%);}
.profile-bg-img{display:flex; flex-direction:column; justify-content:center; margin-bottom:50px; min-height:327px; position:relative; background-color:#003965;}
.profile-bg-img .container{position:relative;}
.profile-bg-img .profile-details{color:#fff; display:flex; position:absolute; left:0px; z-index:0}
.profile-bg-img .profile-img{width:269px; height:250px; overflow:hidden; display:flex; align-items:center; justify-content:center; background-color:#d1ccca; background-position:center center; background-size:cover;}
.profile-bg-img .profile-img img{border-radius:5px;}
.profile-bg-img .profile-basic-details{padding:37px;}
.profile-bg-img .profile-basic-details .verified-text{font-size:13px; font-weight:600;}
.profile-bg-img .profile-details .name{color:#fff; font-size:30px; font-weight:600;}
.profile-bg-img .profile-details .name a{color:#fff; font-size:14px;}
.profile-bg-img .profile-details .location{margin:10px 0;}
.profile-bg-img .profile-details .location .mat-ico{font-size:20px;}
.profile-bg-img .profile-details .ratings-reviews{display:flex; font-size:18px; font-weight:500;}
.profile-bg-img .profile-details .rating-text{align-self:flex-end; line-height:1; margin-left:10px; padding-right:15px; border-right:1px solid; margin-right:15px;}
.profile-bg-img .profile-details .add-review{align-self:flex-end; color:#fff; line-height:1;}

.profile-bg-img.popup .profile-img{width:270px; height:255px; margin-top:0px}

.freelancers-profile .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:inherit;}
.freelancers-profile .left-right-column{position:relative; z-index:100;}
.ant-col.ant-col-18{padding-left: 25px !important; padding-right: 0 !important;}

.profile-wrapper{width:100%}

.ant-tabs.profile-tabs{overflow:initial}
.profile-tabs .ant-tabs-content-holder{padding:35px; background-color:#fff; box-shadow: 0px 7px 24px rgb(0 0 0 / 6%); border-radius: 5px;}
.profile-tabs .ant-tabs-nav-list{background-color:#fff; border-radius:35px; display:flex; justify-content:space-evenly;}
.profile-tabs .ant-tabs-nav-list .ant-tabs-tab{padding:0;}
.profile-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn{padding:8px 26px;}
.profile-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active{background-color:#003965; border-radius:35px;}
.profile-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active span{color:#fff;}
.update-profile-page .profile-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active span{color:#fff;}
.ant-tabs-tab{font-size: 16px;}
.profile-main-wrapper .d-flex, .table-row-employment-form .d-flex, .post-project .d-flex{gap:20px;}
.update-profile-page .main-content-title h1{margin: 30px 0;}
.ant-select-multiple .ant-select-selection-item{height: 32px;align-items: center; background-color: #ebe6e3; color: #434343 !important; border-radius: 16px; padding: 0 10px;}
.update-profile-page .ant-input{border: 1px solid #d9d9d9;}
.update-profile-page  .ant-select:not(.ant-select-customize-input) .ant-select-selector{padding: 4px;}
.update-profile-page .ant-form-item-label > label{height: auto;}
.update-profile-page .ant-form-item.ant-row{align-content: flex-start;}
.update-profile-page .profile-main-wrapper .custom-carousel .d-flex{gap: 0;}
.update-profile-page .custom-carousel .ant-carousel .slick-dots{bottom: -20px;}
.update-profile-page .table-row-employment-form{margin-top: 15px;}
.update-profile-page .custom-carousel .block .text-block{margin: 0; padding: 10px 20px 0;}
.update-profile-page .custom-carousel .block{padding: 20px;}
.update-profile-page .row-portfolio-slider .block .img-block{width: 40%;}
.update-profile-page .custom-education-carousel .block .icon-block {align-self: center;}
.update-profile-page .custom-carousel .slick-slide > div{padding: 0;}
.update-profile-page .row-portfolio-slider .portfolio-image{background-size: cover; border-radius: 5px;}
.update-profile-page .custom-employment-carousel .block .text-block {display: flex; flex-wrap: wrap;}
.update-profile-page .custom-employment-carousel .sl-title, .update-profile-page .custom-employment-carousel .sl-company-name, .update-profile-page .custom-employment-carousel .sl-from-to, .update-profile-page .custom-employment-carousel .sl-text{width: 50%; margin-bottom: 0;}
.update-profile-page .row-portfolio-slider  .ant-carousel .slick-dots{bottom: 10px;}



.ant-tabs.profile-tabs .page-heading{font-size:24px; margin-bottom:30px; font-weight: 600;}

.profile-tabs .ant-tabs-nav-list .ant-tabs-ink-bar{display:none}

.profile-modal-content{display:flex; justify-content:center; align-items:center; flex-direction:column}
.profile-modal-content .profile-icon{background-color:#ee7d22; width:100px; height:100px; display:flex; justify-content:center; align-items:center; border-radius:50%; margin-top:-75px}
.profile-modal-content .profile-icon img{}
.profile-modal .ant-modal-footer{border:0 none}

.profile-modal-content .profile-modal-body{margin-top:40px; display:flex; justify-content:center; align-items:center; flex-direction:column}
.profile-modal-content .profile-modal-body strong{font-size:25px}
.profile-modal-content .profile-modal-body .profile-progress{justify-content:space-around; flex-direction:row; align-items:center; padding:20px 45px; width:250px}
.profile-modal-content .profile-modal-body .profile-progress > span{font-weight:bold; margin-right:10px}
.profile-modal-content .profile-modal-text{padding:10px 80px; text-align:center}
.profile-modal-content .profile-modal-text b{display:block;}
.profile-modal-content .profile-modal-text p{margin-bottom:0}

.page-heading{color:#003965; font-size:24px; margin-bottom:40px; font-weight:bold}
.projects-as-buyer .page-heading, .projects-as-seller .page-heading, .products-list .page-heading{margin: 60px 0 !important; font-weight: 600; font-size: 26px;}
.page-subheading{color:#6E939C; font-size:20px; font-weight:500;}

.project-view .stats{display:flex; justify-content:space-between; padding:15px; border:1px solid #D9D9D9; border-radius:5px; margin-top:10px; align-items:center;}

.stats-list{display:flex;}
.stats-list .single{display:flex; flex-direction:row; align-items:flex-start; padding-right:30px;}
.main-details .stats-list .single{flex-direction:column;}
.stats .stats-list .single{align-items:center;}
.stats-list.lg .single{padding:0}

.profile-basic-details .vertical span{line-height: 1.2;}

.clarification-board-tab .no-messages{border:1px solid #d9d9d9}

.proposal-timeline .message-row{padding-left:200px; padding-right:20px; flex-direction:row-reverse;}
.proposal-timeline .message-row > .img-block{margin-left:10px}
.proposal-timeline .message-row .chat-block{padding:12px; background-color:rgba(0,0,0,.05); border-radius:6px; font-size: 15px; line-height: 1.3em;}
.proposal-timeline .message-row.left-align{padding-right:100px; padding-left:20px; flex-direction:row;}
.proposal-timeline .message-row.left-align .chat-block{background-color:#f07e2210;}
.chat.proposal-timeline .message-row.left-align .chat-block{ font-weight: 500;}
.timeline-block.chat{padding: 0;}

.ant-form label.cloud-uploader{width:100%; padding:15px; justify-content:center; align-items:center; display:flex; background-color:rgba(0, 0, 0, .01); border:1px solid rgba(0, 0, 0, .07); transition:.2s all ease-in-out; cursor:pointer; margin-bottom:10px; flex-direction:column; font-size:65px !important; color:rgba(0,0,0,.1) !important;}
.ant-form label.cloud-uploader p{font-size:15px; color:rgba(0,0,0,.4);}
.new-proposal-details .ant-form label, .clarification-details .ant-form label{font-size: 14px !important; color: #464E5F !important;}
.clarification-details .ant-form label .ant-checkbox + span{font-weight: 400;}
.ant-form label.cloud-uploader:hover{background-color:rgba(0, 0, 0, .02); border:1px solid rgba(0, 0, 0, .1); box-shadow:0 5px 15px rgba(0,0,0,.02);}

.projects-details-left-section  .ant-row-bottom{font-size:16px;}

.new-proposal-wrap{text-align:center; padding:20px; margin:30px 0; border: solid 1px #F07E2280; border-radius: 5px;}
.new-proposal-wrap.awarded{background-color:#fff1da;}
.new-proposal-wrap h3{color:#414141;font-size:20px;line-height:38px;margin:0; text-align: left;}
.new-proposal-wrap h3 i{font-size: 30px; vertical-align: middle; padding-right: 2px; }
.new-proposal-wrap p{line-height:21px;margin:12px 0 15px;}

form{font-size:16px;}
form label,
.ant-form label{color:#003965 !important; font-size:16px !important; font-weight:500;}
form .ant-form-item{font-size:16px;}
form p{color:#6E939C;}
form a{color:#003965; font-weight:600;}
form .buttons-block button{font-size:13px; height:auto; text-transform:uppercase;}
form .buttons-block button:not(:last-child){margin-right:15px;}

.ant-upload.ant-upload-drag{background-color:#F8F8F8 !important; border:0px none; padding:8px 15px;}
.ant-upload.ant-upload-btn{padding:10px 0;}
.ant-upload-drag-container{align-items:center; display:flex !important;}
.ant-upload-drag-icon{margin-bottom:0px !important; margin-right:20px !important;}

.profile-basic-details .top{background-color:#003965; height:100px;}
.profile-basic-details .ant-avatar{align-self:center; margin-top:-50px}

.related-projects-listing .slick-slide + .slick-slide > div{margin-right: 15px; position: relative;}

.proposal-items-block .details{border-left:1px solid rgba(0,0,0,.1); border-right:1px solid rgba(0,0,0,.1); border-bottom:1px solid rgba(0,0,0,.1);}
.proposal-items-block .title{padding:10px; border-bottom:1px solid rgba(0,0,0,.1); display:flex; align-items:center; justify-content:space-between; background-color:#928a87; color:#fff; border-radius:10px 10px 0 0;}
.proposal-items-block .detail-block{border-bottom:1px solid rgba(0,0,0,.1);}

/* .our-team.about .single-member{border:1px solid #DBDBDB; border-radius:0px; box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.13); margin-bottom:10px; margin-top:93px; padding:0px 45px 0px 45px; position:relative;}
.our-team.about .single-member:hover{border-color:inherit; box-shadow:inherit;}
.our-team.about .slick-slide > div{margin:0 15px;}
.our-team.about .img-block{border-radius:50%; -o-border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; position:absolute; left:calc(50% - 93px); top:-93px; width:186px;}
.our-team.about .img-block .user-pic{max-height:100%; max-width:100%;}
.our-team.about .img-block .social-img{border-radius:50%; -o-border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; bottom:15px; height:52px; position:absolute; right:-10px; width:52px;}
.our-team.about .text-block{margin-top:100px; margin-bottom:50px; text-align:center;}
.our-team.about .name{color:#003965; font-size:22px; font-weight:600;}
.our-team.about .designation{font-size:15px; margin-bottom:40px; position:relative;}
.our-team.about .designation::after{background-color:#fc9e00; bottom:-10px; content:''; display:block; height:3px; left:calc(50% - 40px); position:absolute; width:80px;} */

.categories-section.related{margin-top:30px;}
#root > .category .categories-section{padding-bottom:50px;}
#root > .category .sp-projects{padding-top:50px;}

.register-select-section .blocks{display:flex; justify-content:space-between; margin:50px 0;}
.register-select-section .block{align-items:center; border-radius:15px; box-shadow:0 0 10px rgba(144,144,144,.16); display:flex; flex-direction:column; justify-content:center; line-height:1.2; min-height:280px; text-transform:uppercase; width:48%;}
.register-select-section .block{box-shadow: 0px 7px 34px rgb(163 163 163 / 10%); color: #fff; border: solid 1px #e9dbdb; transition:.4s all ease-in-out}
.register-select-section .block:hover{box-shadow:0 0 15px rgba(252,158,0,.24); background-color:#F07E22; }
.register-select-section .block.orng-bg:hover{box-shadow:0 0 15px rgba(252,158,0,.48)}
.register-select-section .block .slim-text{font-size:46px; font-weight:300;}
.register-select-section .block .bold-text{font-size:69px; font-weight:600;}
.register-select-section .block .slim-text{color:#F07E22; transition:.2s all ease-in-out}
.register-select-section .block .bold-text{color:#003965; transition:.2s all ease-in-out}

.register-select-section .block:hover .slim-text{color:#fff;}
.register-select-section .block:hover .bold-text{color:#fff;}

.freelancer-dashboard-page .main-content{background-color:#F3F6F9; background-image:none; padding-top:0px;}
.freelancer-dashboard-page .main-content-title{display:none;}
.banner-block.dashboard-bg-img > img{height:236px; min-width:100%; top:0px;}
.freelancer-dashboard-page .page-heading,
.product-add .page-heading,
.products-view.freelancer-ac .page-heading{color:#fff; font-size:30px; font-weight:500; padding-top:70px; margin-bottom:30px !important;}
.dashboard-bg-img > img{height:236px; top:0px;}
.freelancer-dashboard-page .page-heading{color:#fff; font-size:30px; font-weight:500; padding-top:50px; margin-bottom:20px;}
.freelancer-dashboard-page .profile-details{background-color:#fff; color:#003965;box-shadow: -1px 3px 15px #00000026; display:flex; justify-content:space-between; margin-bottom: 10px;}
.freelancer-dashboard-page .banner-block{background-color: transparent;}
.freelancer-dashboard-page .details-block .left-section .ant-col-8{padding-right: 0 !important; align-self: flex-end;}
.freelancer-dashboard-page .details-block .ant-tabs-tab a{color: #818181; font-size: 16px;}
.freelancer-dashboard-page .details-block .ant-tabs-tab a:hover{color: #434343;}
.freelancer-dashboard-page .details-block .ant-tabs-tab{padding: 8px 12px; margin-right: 10px;}
.freelancer-dashboard-page .details-block .ant-tabs-tab-active a, .project-details-page .ant-tabs-tab-active a{color: #2e2e2e;}
.freelancer-dashboard-page .details-block .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .project-details-page .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .proposal-overview .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
  background-color: #a9d7e36b;border-radius: 5px;  height: 41px;}
.freelancer-dashboard-page .ant-table-thead > tr > th {color: rgb(84 84 84 / 85%); font-weight: 400; background: #928A8710; border-bottom: 1px solid #f0f0f0; font-size: 15px;}
.freelancer-dashboard-page .ant-tabs-content-holder .tab-heading { color: #434343; font-size: 18px; font-weight: 500; margin-bottom: 10px;}

.dashboard-bg-img .profile-details{border-radius:5px; padding:35px 30px;}
.dashboard-bg-img .profile-img{border:2px solid #05C941; border-radius:50%; height:90px; padding:3px; width:90px;}
.dashboard-bg-img .profile-img .ant-image{display:flex; align-items:center; justify-content:center; height:100%}
.dashboard-bg-img .profile-img .ant-image img{width:auto; height:auto}
.freelancer-dashboard-page .user-details{display:flex; justify-content:space-between;}
.dashboard-bg-img .profile-details{color:#003965;}
.dashboard-bg-img .profile-basic-details{padding:6px 20px;}
.dashboard-bg-img .profile-details .name{color:#003965; font-size: 20px; font-weight: 600;}

.freelancer-dashboard-page .profile-details .details-section .ant-btn-link{color: #a5a5a5;}
.freelancer-dashboard-page .ant-table-container table > thead > tr:first-child th:first-child{width: 30%;}

 .container-lg{line-height:1.2; position:relative; z-index:2;}

/* .profile-basic-details .name{margin-bottom:10px;} */
.profile-basic-details .location{display:flex; align-items:center; margin-bottom:10px;}
.section .small-text{font-size:14px; margin-bottom:5px;}
.section .title{font-size:14px; font-weight:500; margin-bottom:5px;}

.section.details-section{max-width:450px; flex:1}
.section .price{font-weight:500;}

.freelancer-dashboard-page .profile-details .ratings-section{padding:4px 30px;}
.ratings-section{border-right:1px solid #C6C6C6; display:flex; justify-content:space-between; padding:20px 30px;}
.profile-details .ratings-section .block{align-items: center;}
.ratings-section .block{display:flex; flex-direction:column;}
.ratings-section .block:not(:last-child){margin-right:30px;}
.ratings-section .block .num{font-size:30px; font-weight: 500;}
.ratings-section .block .num span{font-size:17px;}
.ratings-section .block.seller .num{color:#962C08;}
.ratings-section .block.buyer .num{color:#F07E22;}
.ratings-section .block .text{font-size:13px; font-weight:500;}

.section.links a{color:#003965; font-weight:500; padding:3px 8px; position:relative;}
.section.links a:not(:last-child)::after{background-color:#003965; content:''; display:block; position:absolute; height:14px; right:0px; top:7px; width:1px;}


.products_offers{display:flex; flex-wrap:wrap; padding-right:20px;}
.single-offer{background-color:#fff; border:1px solid #D5D5D5; border-radius:5px; box-shadow:none; overflow:hidden; padding:30px; position:relative;}
.products_offers > .single-offer{margin-right:15px; width:calc(33.33% - 10px);}
.dashboard-page .products_offers > .single-offer{margin-right:15px; width:calc(50% - 10px);}
.products_offers > .single-offer:nth-child(3n){margin-right:0px;}
.single-offer .top-block{border-radius:2px; display:flex; justify-content:center; flex-direction:column; height:157px; overflow:hidden;}
.single-offer .top-block img{border:0px none; width:100%;}
.single-offer .featured-text{background-color:#962C08; color:#fff; font-size:14px; font-weight:500; padding:3px 25px; position:absolute; top:13px; left:-26px; transform:rotate(-45deg);}
.single-offer .wishlist-count{background-color:#F3F6F9; color:#003965; font-weight:500; padding:3px 8px; position:absolute; top:30px; border-radius:5px; right:30px;}
.products-search-section .single-offer .wishlist-count{top:20px; right:20px;}
.single-offer .title{color:#003965; display:block; font-size:17px; font-weight:500; line-height:1.2; margin:16px 0 5px;}
.single-offer .last-activity{color:#6E939C;}
.single-offer .slick-slide > div{margin:0 15px;}
.single-offer .ratings-price,
.single-offer .ratings{display:flex;}
.single-offer .ratings-price{justify-content:space-between;}
.single-offer .rating-text{color:#003965; align-self:center; margin-left:5px;}
.products-listing .single-offer .rating-text{margin-top: 3px;}
.single-offer .price-text{color:#003965; align-self:center; margin-left:5px; font-weight:500;}
.single-offer .price-text .text{color:#6E939C; font-weight:normal; margin-right:3px;} 
.single-offer .bottom-block{display:flex; justify-content:space-between; margin-top:20px;}
.products-listing .single-offer .sold-text, .products-offers-tab .single-offer .sold-text, .single-offer .sold-text{flex-direction:row !important; gap: 2px; align-items: end; margin-bottom: 6px;}
.single-offer .sold-text{color:#6E939C; display:flex; flex-direction:column; line-height:1;}
.single-offer .sold-text .num{color:#962c08; font-size:16px; font-weight:500;}
.single-offer .ant-select{border:1px solid #F07E22; border-radius:5px; overflow:hidden;}
.single-offer .ant-select-selector{background-color:transparent; border:0px none; min-height:auto;}
.single-offer .ant-select-selection-placeholder{color:#F07E22; font-size:15px;font-weight:500;}
.single-offer .ant-select-arrow{color:#F07E22;}

.listing.products_offers{padding-right:0px;}
.listing.products_offers .ant-list-items{display:flex; flex-wrap:wrap;}
.single-offer{margin-bottom:30px; margin-right:30px;}

.single-offer .ant-select-selector{height:auto !important;}
.single-offer .ant-select-selection-placeholder{line-height:38px !important;}

.listing .single-offer{width:45%}
.products-search-section .listing .single-offer{width:48%}
.products-search-section .products_offers .single-offer{padding: 20px !important; margin: 0;}
.products-search-section .ant-list-items{justify-content: space-between; row-gap: 20px;}

.view-details-left-blk .top-section{overflow:hidden; position:relative;}
.tag-wrapper{background-color:#962c08; color:#fff; font-size:13px; font-weight:500; left:-28px; padding:4px 28px; position:absolute; top:12px; transform:rotate(-45deg); z-index:99;}

.product-image{border:1px solid #f4f4f4; display:flex; height:400px; justify-content:center; margin-bottom:15px;}
.product-gallery{display:flex; flex-wrap:wrap;}
.product-gallery .g-img{align-items:center; border:3px solid #f4f4f4; cursor:pointer; display:flex; flex-direction:column; justify-content:center; margin-right:15px; width:calc(16.66% - 12.5px); padding:2px;}
.product-gallery .g-img:nth-child(6n){margin-right:0px;}

.product-gallery .g-img.active{border:3px solid #ee7d22}

.clarification-chat{max-width:90%; margin-left:10%; margin-top:50px}
.clarification-chat .message-row.left-align{padding-left:100px}

.response-block{border:1px solid #d9d9d9; border-radius:5px; color:#6e939c; margin-bottom:30px; padding:10px; text-align:center; width:100%;}
.response-block .ico{margin-bottom:4px;}
.response-block .blue-txt{color:#003965;}

.money-protection{align-items:center; background-color:#fff; display:flex; justify-content:space-between; margin-bottom:20px; margin-left: 20px; padding:20px 24px;}
.money-protection .details{width:calc(100% - 80px);}
.money-protection .title{color:#003965; font-size:17px; font-weight:500; margin-bottom: 0;}
.money-protection .text{color:#6e939c; font-size: 15px;}

/* .money-protection-block .ant-col:before{ display: flex; flex-direction: column; justify-content: center; margin: auto; width: 30px; height: 30px; line-height: 1em; vertical-align: middle; background-color: #fff; border-radius: 20px; box-shadow: 0 5px 20px #ccc;} */
/* .money-protection-block .ant-col:nth-child(1):before{content: '1'; display: flex; flex-direction: column; justify-content: center; margin: auto; width: 30px; height: 30px; line-height: 1em; vertical-align: middle; background-color: #fff; border-radius: 20px; box-shadow: 0 5px 20px #ccc;}
.money-protection-block .ant-col:nth-child(2):before{content: '1'; display: flex; flex-direction: column; justify-content: center; margin: auto; width: 30px; height: 30px; line-height: 1em; vertical-align: middle; background-color: #fff; border-radius: 20px; box-shadow: 0 5px 20px #ccc;}
.money-protection-block .ant-col:nth-child(2):before{content: '1'; display: flex; flex-direction: column; justify-content: center; margin: auto; width: 30px; height: 30px; line-height: 1em; vertical-align: middle; background-color: #fff; border-radius: 20px; box-shadow: 0 5px 20px #ccc;} */

/* .blue-tabs ol{color:#6e939c; padding-left:10px;}
.blue-tabs li::marker{color:#003965; font-weight:600;}
.blue-gr-bg{background-image:linear-gradient(to bottom, #d7e8f4, #e3edf8, #eff2fa, #f8f8fd, #ffffff); z-index:1;}
.blue-gr-bg .heading-container{margin-top:-180px; position:relative; z-index:1;}
.product-add ol{padding-left:15px;}
.product-add .ant-collapse{background-color:transparent; border:0px none;}
.product-add .ant-collapse .ant-collapse-header{background-color:#FFF1E5; color:#962C08 !important; font-size:16px; font-weight:700; padding:12px 30px !important;}
.product-add .ant-collapse-header .anticon{border:2px solid #962C08; border-radius:50%; -o-border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; padding:5px;}
.product-add .ant-collapse-header .anticon svg{height:15px; width:15px;}
.product-add .ant-collapse > .ant-collapse-item{border:0px none; box-shadow:0 0 27px rgba(77,77,77,0.16); margin-bottom:20px;}

.product-add .ant-collapse-content-box{color:#6E939C; line-height:2; padding:30px;}
.product-add .ant-collapse-content-box ul,
.product-add .ant-collapse-content-box ol{margin-bottom:0px;}
.product-add .ant-collapse-content-box li{margin-bottom:10px;}
.product-add .ant-collapse-content-box a{color:#962C08;} */

.white-block{background-color:#fff; padding:40px;}
.product-add .white-block{box-shadow:0 0 27px rgba(77,77,77,0.16);}
.frm-white-block{}
.frm-white-block .ant-input,
.frm-white-block .ant-input-number-input{height:47px;}
.frm-white-block .ant-input{border:0px none;}
.frm-white-block .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height:40px; padding:5px 15px;}
.frm-white-block .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{background-color:#f8f8f8; border:0px none; min-height:40px; padding:5px 15px;}
.frm-white-block .d-flex .w-20{width:18%;}
.frm-white-block .d-flex .w-60{width:58%;}

.frm-white-block .last .ant-row{width:calc(100% - 43px);}
.frm-white-block .last input{border-bottom-right-radius:0px; border-top-right-radius:0px;}
.remove-addon,
.clear-total{align-self:flex-start; border:0px none; border-bottom-left-radius:0px; border-top-left-radius:0px; height:47px; padding:12px 15px; width:43px;}
.extra-addons > div:first-child .remove-addon{margin-top:40px;}
.btn-lgreen{background-color:#6E939C; color:#fff;}
.btn-lgreen:hover,
.btn-lgreen:active,
.btn-lgreen:focus{background-color:rgba(110,147,156,.7); color:#fff;}
.no-label .ant-form-item-label{display:none;}

.add-addon-block{margin-bottom:20px; text-align:right;}

.orng-checkbox{}
.orng-checkbox .ant-checkbox-inner{height:23px; width:23px;}
.orng-checkbox .ant-checkbox-checked .ant-checkbox-inner{background-color:#F07E22; border-color:#F07E22;}
.orng-checkbox .ant-checkbox-checked .ant-checkbox-inner::after{height:14px; top:40%; width:7px;}

.frm-white-block .note,
.frm-white-block .orng-checkbox{margin-top:10px;}
.frm-white-block .ant-checkbox + span{align-self:center; color:#adaaaa; font-size:16px; font-weight:400;}

.frm-white-block label.upload-panel{background-color:#F8F8F8; display:flex; align-items:center; padding:30px; cursor:pointer; transition:.2s all ease-in-out}
.frm-white-block label.upload-panel:hover{background-color:rgba(115, 3, 192, .03); border-color:rgba(115, 3, 192, .3)}

.frm-white-block label.upload-panel p{margin:0; font-weight:normal; text-transform:capitalize; color:rgba(0,0,0,.5);}

.frm-white-block .form-gallery ul li{flex-direction:column; height:200px; width:200px;}
.frm-white-block .form-gallery .img-block{max-height:calc(100% - 40px); max-width:100%; overflow:hidden; text-align:center;}
.frm-white-block .form-gallery .text{font-size:12px; max-width:100%; position:relative; word-wrap:break-word;}
.frm-white-block .form-gallery .name{max-width:calc(100% - 20px);}
.frm-white-block .form-gallery ul li .close{background-color:transparent; opacity:1;}
.frm-white-block .form-gallery .close svg{color:#6E939C;}

.frm-white-block label.ant-radio-wrapper{color:#6E939C !important; margin-bottom:10px;}
.frm-white-block label.ant-radio-wrapper.ant-radio-wrapper-checked{color:#003965 !important;}
form .ant-radio-wrapper .ant-radio-inner::after{background-color:#F07E22;}

.frm-white-block .ant-radio-checked::after{border:1px solid #F07E22;}
.frm-white-block .ant-radio-checked .ant-radio-inner{border-color:#F07E22;}
.link{color:#962C08; font-weight:400;}

.send-message-block{background-color:#f5f5f5; padding:30px 30px 0 0; margin-bottom:0px;}

.milestone-form-block .heading-block label{color:#414141; font-size:16px; padding:10px 0; width:50%;}
.milestone-form-block form,

.ant-progress-line{line-height:1;}

.main-content.account-bg{background-color:#F3F6F9; background-image:none;}
.messages-wrapper{width:100%}
.sidebar-col{background-color:#fff; height:calc(100% - 90px)}
.sidebar-col-section{background-color:#fff; margin-bottom:3px; padding:15px}
.sidebar-col-section.main{border-top:3px solid #fafafa}

.messages-wrapper .sidebar-col-section.main{padding:15px 0}

.user-messages-list .message-row{border-bottom:0 none}

.sidebar-filter-dropdown-wrapper{height:90px}
.sidebar-filter-dropdown{display:flex; justify-content:center; align-items:center; border:1px solid #191919; border-radius:30px; padding:7px; background-color:#fff; font-size:14px; font-weight:600; width:200px}
.sidebar-filter-dropdown strong{margin-left:10px}

.account-bg .page-heading{margin-bottom:20px;}
.ant-tabs-ink-bar{background-color:#f07e22;}
.manage-projects .ant-layout{background-color:#fafafa}
.page-main-content{margin-top:20px;}
.manage-projects .page-heading{font-size:25px; font-weight:bold; padding-left:10px;}

.page-section-heading{margin-top:25px; margin-bottom:35px;}
.tab-heading{color:#000; font-size:20px; font-weight:bold; margin-bottom:10px;}
.tab-sub-heading{color:rgba(0, 0, 0, .5); font-size:20px; font-weight:normal; line-height:1;}

.manage-projects .ant-tabs .ant-tabs-left-bar{border:0px none;}
.manage-projects .ant-tabs-left-content{padding:50px 40px; width:calc(100% - 290px);}

.manage-projects .ant-table-tbody > tr > td img{border-radius:50%; height:30px; width:30px;}
.manage-projects .ant-table-thead .ant-table-thead > tr > th{padding:14px 18px;}

.ant-tabs .ant-tabs-left-bar{border:0 none}

.ant-table table tbody{border:1px solid #e7e6e6;}
.ant-table-pagination.ant-pagination{display:flex; float:none; justify-content:center;}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next{min-width:24px; height:24px;}
.ant-table-pagination.ant-pagination li{border:0px none;}
.ant-table-pagination.ant-pagination li a{border:0px none; border-radius:50%; display:flex; flex-direction:column; font-weight:bold; height:24px; justify-content:center; text-align:center; width:24px;}
.ant-table-pagination.ant-pagination .ant-pagination-item{line-height:24px; height:24px; min-width:24px; width:24px;}
.ant-table-pagination.ant-pagination .ant-pagination-prev a,
.ant-table-pagination.ant-pagination .ant-pagination-next a{color:#F07E22;}
.ant-table-pagination.ant-pagination .ant-pagination-disabled a{color:#cbcbcb; font-weight:600;}
.ant-table-pagination.ant-pagination .ant-pagination-item-active a{background-color:#F07E22; color:#fff;}

.ant-list-split ul li.ant-list-item{border-bottom:0px none;}

/*  New Css Start   */
.btn-tabs .ant-tabs-nav-list{border:1px solid #a24921; border-radius:5px; overflow:hidden;}
.btn-tabs .ant-tabs-tab{color:#a24921; margin-left:0px; margin-right:0px; min-width:150px; padding:10px 20px; text-align:center;}
.btn-tabs .ant-tabs-tab .ant-tabs-tab-btn{margin:0 auto;}
.btn-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#fff;}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{text-shadow: none;}
.ant-tabs-top > .ant-tabs-nav::before{border-bottom:0px none;}
.btn-tabs .ant-tabs-tab-active,
.btn-tabs .ant-tabs-tab:hover{background-color:#a24921; color:#fff;}
.btn-tabs .ant-tabs-ink-bar-animated{display:none !important;}

.ant-checkbox-wrapper.orng .ant-checkbox-inner{border-color:#F07E22;}
.ant-checkbox-wrapper.orng .ant-checkbox-checked .ant-checkbox-inner{background-color:#F07E22; border-color:#F07E22;}

.cms-page b{color:#003965;}
.cms-page a{color:#F07E22;}
.cms-page h2{color:#003965; font-size:20px; margin-top: 20px;}
.cms-page h3{color:#962c08; margin-top: 20px; margin-bottom: 0; font-weight: 600; font-size: 18px;}
.cms-page li::marker{color:#003965; font-size:20px; font-weight:500;}
.cms-page .heading {padding-bottom: 0.5em;}
.how-we-work-page .sub-heading{font-size: 20px; font-weight: 600; color: #962c08; margin-bottom: 10px;}

.blk-slider.ptb .slick-list{padding-top:100px; padding-bottom:30px;}
.blk-slider .single-member:hover{border-color:#FC9E00; box-shadow: 0px 6px 18px rgb(144 144 144 / 9%);}
.blk-slider .slick-slide > div{margin:0 15px;}

.button-tabs.ant-tabs-bar{border:0px none; margin-bottom:25px;}
.button-tabs .ant-tabs-tab{font-size:14px; padding:6px 13px; border-radius:5px; text-transform:uppercase;}
.freelancer-profile-page .button-tabs .ant-tabs-tab{margin-right: 0;}
.ant-tabs-tab + .ant-tabs-tab{margin-left: 10px;}
.cms-page .ant-tabs-tab + .ant-tabs-tab{margin-left: 0;}
.cms-page p, .cms-page li{font-size: 16px; margin-bottom: 10px; color: #434343;}
.button-tabs .ant-tabs-tab-active,
.button-tabs .ant-tabs-tab:hover{background-color:#F07E22; color:#fff;}
.freelancer-profile-page .button-tabs .ant-tabs-tab:hover{background-color: #eddbcb; color: rgba(0, 0, 0, 0.85);}
.button-tabs .ant-tabs-top .ant-tabs-ink-bar-animated{display:none !important;}
.button-tabs .ant-tabs-nav::before{display:none;}
.button-tabs.blue .ant-tabs-tab-active,
.button-tabs.blue .ant-tabs-tab:hover{background-color:#003965; color:#fff;}
.button-tabs .ant-tabs-ink-bar{display:none}

.our-team.about .text{max-height:50px; transition:max-height 0.5s ease-in-out;}
/* .our-team.about .single-member:hover .name,
.our-team.about .single-member:hover .designation{color:#fc9e00;}*/
.our-team.about .single-member:hover .text{max-height:1000px; transition: all 1s ease-in;} 

.img-btn-tabs .ant-tabs-nav-list{background-color:rgb(248, 248, 248); border-top-left-radius:10px; border-top-right-radius:10px; overflow:hidden; padding:25px; width:100%;}
.img-btn-tabs .ant-tabs-nav{margin-bottom:0px;}
.img-btn-tabs .ant-tabs-tab{border:0px none; flex:1; padding:16px;}
.img-btn-tabs.p-tab-10 .ant-tabs-tab{padding:10px;}
.img-btn-tabs .ant-tabs-tab.ant-tabs-tab-active,
.img-btn-tabs .ant-tabs-tab:hover{background-color:#fff; border-radius:10px; -o-border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px;}
.tac-tabs .ant-tabs-tab.ant-tabs-tab-active .title{color:#003965;}
.img-btn-tabs .ant-tabs-tab:hover .title{color:#fc9e00;}
.img-btn-tabs .ant-tabs-tab-btn,
.img-btn-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#6E939C; font-size:16px; text-align:center;  width:100%;}
.img-btn-tabs .ant-tabs-tab-btn img{filter:grayscale(1);}
.img-btn-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn img{filter:grayscale(0);}
.img-btn-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn img{filter:grayscale(1);}
.img-btn-tabs .ant-tabs-ink-bar-animated{display:none;}

.img-btn-tabs .bordered{border-bottom-left-radius:10px; border-bottom-right-radius:10px; color:#6E939C; min-height:250px;}
.img-btn-tabs .ant-tabs-tabpane ul{color:#003965; display:flex; flex-wrap:wrap; font-size:16px; justify-content:space-between; list-style-type:disc; margin:16px 0 10px 30px; padding-left:48px;}
.img-btn-tabs .ant-tabs-tabpane ul li{margin:4px 0; width:48%;}

.challenges-block .img-block img{max-width:unset; position:absolute; right:-130px; top:-70px; width:650px;}

.reviews-block .subtitle{line-height:1.1;}
.reviews-block .title::before{background-image:url('../images/quotes.svg'); content:''; display:block; height:41px; position:absolute; left:0px; top:0px; width:44px;}

.tac-steps::before{border-top:dashed 2px #A3A3A350; content:''; display:block; left:10%; position:absolute; top:20px; width:80%; z-index:0;}
.tac-steps .blk .num{box-shadow:1px 2px 10px #00000010;}

.tick-list .ant-avatar-icon{color:#f07e22; line-height:1;}
.tick-list:not(.num-list) .ant-avatar-icon{background-color:transparent; height:auto; width:auto;}
.tick-list .ant-list-item-meta-description{color:#4E413B; font-size:inherit;}
.tick-list .heading.lg{margin-bottom:20px;}

.prices-top-section::before{border-bottom:180px solid #FBFBFB; border-left:100vw solid transparent; bottom:38%; content:' '; position:absolute; width:0;}
.prices-top-section::after{background-color:#FBFBFB; bottom:0px; content:''; height:38%; position:absolute; width:100%;}
.prices-top-section .block .img-block{box-shadow:0 0 30px #eee; height:254px; width:254px;}
.prices-top-section .block .img-block img{max-width:118px;}

.prices-bottom-section .single-package .top-block .highlight-text{left:calc(50% - 60px); top:-18px; width:120px;}
.prices-bottom-section .single-package .mat-ico{color:#F07E22; font-size:15px;}

.cc-form-container{margin:20px 0}
.cc-form{padding:20px; box-shadow:0 0 5px rgba(0,0,0,.2); background-color:#fff; border-radius:5px; margin-bottom:20px;}
.cc-form-actions{display:flex; justify-content:flex-end;}

.freelancers-search-section .ant-list-items .profile-image .ant-avatar, .post-project .ant-list-items .profile-image .ant-avatar{width: 80px !important; height: 80px !important;}
.freelancers-search-section .ant-list-items .profile-image, .post-project .ant-list-items .profile-image {text-align: left;}
.freelancers-search-section .row-item .right {width: calc(100% - 102px);}
.freelancers-search-section .row-item .left {width: 102px;}
.freelancers-search-section .row-item .ant-typography, .post-project .projects-list .row-item .ant-typography{font-size: 16px; margin-bottom: 10px;}
.freelancers-search-section .row-item, .projects-search-section .row-item {border-color: #dedede;}
.freelancers-search-section .row-item:hover, .projects-search-section .row-item:hover, .row-item:hover {border-color: #f07e22;}
.freelancers-search-section .row-item .profile-image .featured-text {font-size: 13px; left: -20px; top: -20px; padding: 5px 8px; border-radius: 8px 0 8px 0;}

.signup-section{display: flex; padding: 30px; border: solid 1px #D9D9D9; border-radius: 10px; margin-top: 20px;}
.signup-section .ant-btn-primary{height: 50px; padding: 5px 30px;}

.product-view .tips-tab ol li{margin-bottom: 10px;}

.blog-listing-section .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{padding: 7px 16px;}
.blog-listing-section .ant-collapse-item .ant-collapse-header{font-size: 18px;}
.blog-listing-section .ant-collapse {box-shadow: 0px 7px 18px #cccccc1c;}
.blog-listing-section .ant-checkbox-wrapper + .ant-checkbox-wrapper {margin-left: 0; margin-top: 7px;}
.blog-listing-section .ant-checkbox-wrapper{display: flex; font-size: 15px;}
.blog-listing-section .other-posts-block .single-item{border: solid 1px #fff; border-radius: 5px; transition: all 0.4s ease-out;}
.blog-listing-section .other-posts-block .single-item:hover{border: solid 1px #81818159;}

.ant-menu-submenu-popup{z-index: 9999;}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-item a:hover {
  color: #fc9e00;}
  .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{height: auto; line-height: 1.2; padding: 8px; padding-left: 20px;}


/* width */
.filters .filter-list .single::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.filters .filter-list .single::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  background-color: #f1f1f1;
  border-radius: 10px;
}
 
/* Handle */
.filters .filter-list .single::-webkit-scrollbar-thumb {
  background: #cbc1bd; 
  border-radius: 10px;
}

/* Handle on hover */
.filters .filter-list .single::-webkit-scrollbar-thumb:hover {
  background: #928A87; 
}

@media only screen and (max-width:767px){
.container{max-width:100%; padding:0 15px;}
.small-heading{font-size:18px;}
.heading{font-size:22px; line-height:1.2;} 
.btn-brown{padding:7px 10px;}
.btn-white{padding:7px 10px;}

.nav-link.submenu{position:relative}
.nav-link.submenu:after{position:absolute; content:''; display:block; right:25px; top:22px; border-top:8px solid rgba(255,255,255,.5); border-left:8px solid transparent; border-right:8px solid transparent}
.nav-link.submenu:hover:after{border-top:8px solid #4e413b}

.sub-menu-wrapper.show{display:block; width:100%; left:0; top:auto; position:relative;}
.sub-menu-wrapper.show .sub-menu{opacity:1; flex-direction:column;}
.sub-menu-wrapper.show .sub-menu .nav-link{width:100%}
.sub-menu-wrapper.show .sub-menu .nav-link a{padding:10px 20px;}

.navigation .nav-lines{padding:5px; height:auto; width:30px; cursor:pointer;}
.nav-lines .line{transition:.1s all ease-in-out; margin-bottom:4px;}

.nav-lines.active{}
.nav-lines.active .line:first-child{transform:rotate(45deg) translateX(4px) translateY(5px)}
.nav-lines.active .line:nth-child(2){transform:translateX(-100px)}
.nav-lines.active .line:last-child{transform:rotate(-45deg) translateX(5px) translateY(-6px)}

.header-block .second-block,
.header-block .third-block{display:none;}
.mega-menu .menu{width:100%;}
.mega-menu .menu .sub-menu,
.mega-menu .menu .sub-menu-2{left:0px; padding-top:0px; position:relative;}

.mega-menu{padding-top:13px;}

.ant-layout-footer .top-block{ padding: 20px 0 0px}
.ant-layout-footer .footer-block .block{margin-bottom:22px; padding:0px; width:50%;}
.ant-layout-footer .copyright .block{flex-direction:column;}
.ant-layout-footer .copyright .block .right{margin-top:15px;}

.banners .banners-block{background-position:25% 5%; height:300px; padding-top:50px;}
.banners .head-text{font-size:30px;}

.trusted-partners-section .container{flex-direction:column;}
.trusted-partners-section .left-block,
.trusted-partners-section .right-block{width:100%;}

.categories-section .categories-list .category-block{width:48%; margin-bottom: 11px;}
#root > .category .categories-section{padding-bottom: 0;}
.categories-section .heading{margin-bottom: 30px;}
.sp-projects-block .ant-tabs-nav-list{margin-bottom: 5px;}
.inner-page .heading {font-size: 22px !important;}
.sl-tags .heading{font-size: 16px;}

.img-text-section{flex-direction:column;}
.img-text-section.rev{flex-direction:column-reverse;}
.img-text-section > div{width:100%;}
.img-text-section .text-block .block{padding:50px 20px;}
.img-text-section > div.img-block{min-height:300px;}


.sp-projects-block .nav-tabs .nav-link{font-size:16px; padding:8px 10px;}
.projects-listing .single-project .top-block,
.projects-listing .single-project .bottom-block{padding:15px 10px;}
/* .sp-list-block{flex-direction:column;} */
.homepage .sp-list-block .text{font-size: 14px;}
.homepage .sp-list-block .num{font-size: 16px;}
.sp-list-block > div{width:100%;}
.sp-list-block > div{padding:15px; align-self: flex-start;}
.homepage .sp-list-block { padding: 13px 0;}

.how-we-work-block{flex-direction:column;}
.how-we-work-block > .left{margin-bottom:20px; width:100%;}
.how-we-work-block > .right{width:100%;}
.how-we-work-block .blocks .left,
.how-we-work-block .blocks .right{max-width:48%;}
.how-we-work-block .block{padding:20px;}
.how-we-work-block .block-title{line-height:1.2;}

.money-protection-block .blocks{flex-direction:column;}
.money-protection-block .block{margin-bottom:20px; width:100%;}

.w-50, .w-48{width:100%;}
section > div > div > .w-100, section > div > div > .w-50{padding-left: 15px; padding-right: 15px; align-self: flex-start !important;}
body{line-height: 1.3em;}
.container-lg{width: 100%;}
.gig_container{width: 100% !important; }
.service-partners .gig_container{margin-top: 5px !important;}
.ant-layout-header{padding-left: 15px; padding-right: 15px; height: 73px;}
.gig_container #client-carousel figure {flex-basis: 33%;}
.mb-hidden{ display: none;}
.main-content{padding-bottom:0;}

.homepage .banner-slider .head-text, .category .banner-slider .head-text {font-size: 22.5px; line-height: 1.2;}
.category .banner-slider .head-text {margin-bottom: 5px;}
.homepage .banner-slider .ant-carousel .slick-slide img{max-width: auto !important; height: 300px;}
.category .banner-slider .bg-block img, .category .banner-slider .bg-block, .category .banner-slider .banners-block{min-height: 265px;}
.homepage .banner-slider .block{margin-left: 20px; margin-right: 30px; max-width: 280px;}
.category .banner-slider .block{margin-left: 20px; margin-right: 30px; max-width: 200px;}
.sub-category .chip {padding: 6px 18px; line-height: 1.1;}
.homepage .banner-slider .description, .category .banner-slider .description {font-size: 17px; margin-bottom: 19px; margin-top: 10px; line-height: 1.2em;}
.sub-category .banner-slider .description{display: none;}
.sub-category .banner-slider .links{white-space: nowrap; margin-top: 10px;}
.sub-category .banner-slider .links a button{padding-left: 13px !important; padding-right: 13px !important;}
.homepage .banner-slider .ant-btn.px-20 {padding-left: 10px; padding-right: 10px;}
.homepage .trusted-partners-section {padding-top: 25px;}
.categories-section .categories-list .category-block .py-50 {padding-bottom: 20px; padding-top: 20px;}
.category-block .main {min-height: 180px;}
.homepage .category-block.fs-20{font-size: 17px;}
.categories-section{padding: 15px 0 30px;}
.projects-listing .row-item{padding: 20px !important;}
.projects-listing .row-item .left{width: 26%;}
.projects-listing .row-item .right{width: 74%;}
.projects-listing .row-item .mt-10{margin-top: 0;}
.projects-listing .row-item .left{display: flex; gap: 10px; margin-bottom: 10px; text-align: left !important;}
.projects-listing .row-item .left .text-center{text-align: left !important;}
.projects-listing .row-item .left .ant-avatar{width: 70px !important; height: 70px !important;}
.m-fs-16{font-size: 18px !important;}
/* .projects-listing .slick-slide{max-width: 300px;} */
.projects-listing .ant-carousel .slick-arrow, .products-listing .ant-carousel .slick-arrow{display: none !important;}
.projects-listing .slick-slide > div{margin: 0 5px;}
.sp-projects-block .ant-tabs-tab {padding: 4px 6px; font-size: 15px; margin: 0 0 0 10px;}
.projects-listing .title {font-size: 17px;}
.projects-listing .tag {font-size: 13px;}
.single-project div.ant-typography {font-size: 14px;}
.products-listing.products_offers .single-offer .top-block, .products-search-section .single-offer .top-block{height: 130px;}
.products-listing.products_offers .single-offer, .products-search-section .single-offer{margin-right: 5px;}
.products-listing.products_offers, .products-search-section .products_offers{padding-right: 0;}
.products-listing.products_offers .single-offer .mid-block, .products-search-section .single-offer .mid-block, .products-listing.products_offers .single-offer .bottom-block, .products-search-section .single-offer .bottom-block {padding: 0 20px;}
.products-listing.products_offers .single-offer .title, .products-search-section .single-offer .title {font-size: 16px !important; min-height: auto;}
.products-listing.products_offers .single-offer .bottom-block, .products-search-section .single-offer .bottom-block{flex-wrap: wrap; margin-top: 5px;}
.products-listing.products_offers .single-offer .bottom-block .ratings-price, .products-search-section .single-offer .bottom-block .ratings-price{width: 100%; }
.projects-listing .row-item .right .rating_box ul{width: 50%;}
.single-offer .wishlist-count {top: 16px; right: 16px;}
.projects-listing .ant-carousel .slick-dots li button, .products-listing .ant-carousel .slick-dots li button { background: #2c2c2c;}
.projects-listing .ant-carousel .slick-slide, .products-listing .ant-carousel .slick-slide{margin-bottom: 20px;}
.homepage .how-we-work{padding-top: 2em; padding-bottom: 2em;}
.how-we-work-block .blocks .left, .how-we-work-block .blocks .right {width: 100%; max-width: 100%; margin-right: 0px;}
.how-we-work-block .right > div{flex-direction:column;}
.how-we-work-block .right .card{margin-top: 0; margin-bottom: 15px;}
.money-protection-block .ant-row{flex-direction: column;}
.ant-row .ant-col-8{width: 100%; flex-basis: 100%; max-width: 100%;}
.money-protection-block .ant-row .ant-col-8 > div{padding: 10px;}
.category-block .main img {width: 72px;}
.category-block .cat-heading{font-size: 17px;}
.our-story .ant-carousel .slick-initialized .slick-slide{width: 360px !important; margin-bottom: 120px;}
.our-story .ant-carousel .slick-slide img{margin: auto; width: 60%;}
.blk-slider.our-team.about .img-block a{width: 24%; right: 32px;}
.our-team.about .text {max-height: 78px;}
.profile-bg-img .profile-img{width:150px; height:150px;}
.profile-bg-img .profile-basic-details {padding: 0 15px 15px;}
.profile-bg-img .profile-details{left: 13px;}
.profile-bg-img .profile-details .name { font-size: 22px; margin-bottom: 3px;}
.profile-bg-img .profile-banner {height: 230px;}
.freelancer-profile-page .banner-block {min-height: 140px;}
.profile-bg-img .profile-details .ratings-reviews {font-size: 16px; flex-wrap: wrap;}
.profile-bg-img .profile-details .location{margin: 0 0 3px;}
.profile-bg-img .profile-details .add-review{margin-top: 10px;}
.freelancer-profile-page .left-right-column{flex-direction: column;}
.freelancer-profile-page .left-right-column .ant-col-6, .freelancer-profile-page .ant-col.ant-col-18, .listing-page .ant-col-6,  .listing-page .ant-col.ant-col-18, .ant-col-17,.listing-page .ant-col-7, .ant-col-16{flex-basis: 100%; max-width: 100%; padding: 0 8px !important;}
.freelancers-profile-left-section.mt-65 {margin-top: 43px;}
.freelancers-profile-left-section .ant-btn-primary{padding-bottom: 11px; padding-top: 11px;}
.freelancers-profile-left-section .section.buttons.justify-between{justify-content: flex-start; gap: 10px;}
.freelancers-profile-left-section .section .tags{ white-space: nowrap; overflow: scroll;}
.freelancers-profile-right-section{margin-top: 20px;}
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {margin: 0 0 13px 0;}
.freelancer-profile-page .ant-tabs-tab + .ant-tabs-tab {margin-left: 5px;}
.freelancers-profile-right-section .box-white {padding: 24px;}
.freelancers-profile-right-section .text-right{text-align: left;}

.freelancer-dashboard-page{overflow-x: hidden;}
.freelancer-dashboard-page .profile-details{flex-wrap: wrap;}
.freelancer-dashboard-page .ant-col-7{max-width: 100%; flex-basis: 100%;}
.freelancer-dashboard-page .dashboard-bg-img .container-lg{padding: 0 10px;}
.freelancer-dashboard-page .container-lg div.pl-20{padding: 0;}
.freelancer-dashboard-page .container-lg  .ant-row{margin-left: 0 !important; margin-right: 0 !important;}
.left-section > .ant-row > .ant-col-8{max-width: 50%; flex-basis: 50%;}
.left-section > .ant-row > .ant-col-8:last-child{max-width: 100%; flex-basis: 100%; display: flex; flex-wrap: wrap; column-gap: 10px;}
.left-section > .ant-row > .ant-col-8:last-child > div:first-child{max-width: 100%; flex-basis: 100%;}
.left-section > .ant-row > .ant-col-8 > .sm-block{width: 48%;}
.left-section > .ant-row > .ant-col-8 .mt-40{margin-top: 10px;}
.left-section > .ant-row > .ant-col-8 > .vertical{padding: 5px !important;}
.freelancer-dashboard-page .container-lg .details-block .ant-row > .ant-col{padding-left: 0 !important; padding-right: 0 !important;}
.freelancer-dashboard-page .right-section{padding: 10px; width: 380px; }
.freelancer-dashboard-page .ant-tabs {overflow-x: scroll;}
.freelancer-dashboard-page .details-block{display: block;}
.freelancer-dashboard-page .details-block .ant-row > .ant-col-24{overflow: hidden;}
.freelancer-dashboard-page .dashboard-page > .ant-row{flex-direction: column-reverse;}
.dashboard-bg-img .profile-details {padding: 23px 20px;}
.freelancer-dashboard-page .profile-details .ratings-section{width: 100%; order: 2; margin-top: 10px;}
.freelancer-dashboard-page .profile-details .section.details-section{max-width: 100%; flex-basis: 100%; margin-top: 10px;}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{overflow: visible;}



.portfolio-tab .blocks .row-portfolio-slider{width: 100%;}
.portfolio-tab .blocks .portfolio-image {height: 190px;}
.freelancers-search-section .ant-col-17{width: 100%;}
.freelancers-search-section .ant-row{flex-direction: column;}
.fs-35 {font-size: 20px !important;}
.search-row{margin-bottom:10px !important;}
.search-count {font-size: 17px;}
.listing .verified {margin-left: 0; display: block;}
.freelancers-search-section .ant-list-items .profile-image .ant-avatar {height: 93px !important;}
.freelancers-search-section .right-section.white-bg{margin-top: 10px;}
.products-search-section .listing .single-offer {width: 100%;}
.listing-section.pr-20{padding-right: 0;}
.listing-section.px-20{padding-left: 0; padding-right: 0;}
.projects-search-section .top-section.flex{flex-direction: column; align-items: flex-start;}
.projects-search-section .fs-22{font-size: 19px;}
.projects-search-section .price-section{flex-direction: row; margin-top: 0px; align-items: baseline; gap: 5px;}
.projects-search-section .price-section .fs-20{font-size: 16px !important;}
.projects-search-section .price-section .fs-12{font-size: 14px !important;}
.projects-search-section .mid-section .ant-tag{ margin-top: 10px;}
.projects-search-section .bottom-section{flex-direction: column; align-items: flex-start; row-gap: 10px;}
.projects-search-section .bottom-section .w-25{width: 50%;}
.projects-search-section .bottom-section .details{width: 100%; row-gap: 5px;}

.project-details-page .left-right-column{flex-direction: column-reverse;}
.project-details-page .profile-card.pl-20{padding-left: 0; padding-bottom: 10px;}
.project-details-page .top-section .fs-26{font-size: 20px;}
.project-details-page .top-section .stats{flex-direction: column;}
.project-details-page .top-section .stats .stats-list{width: 100%; justify-content: space-between; margin-bottom: 10px; }
.project-details-page .top-section .stats .stats-list .single{flex-direction: row; gap:5px; padding: 0 !important; align-items: flex-start;}
.project-details-page .ant-tabs.mt-50{margin-top: 20px;}
.project-details-page .info-tab .stats-list { flex-direction: column; }
.project-details-page .single.w-33{ width: 100%; flex-direction: row-reverse; gap: 10px; justify-content: flex-end;}
.project-details-page .fs-22{font-size: 18px;}
.project-details-page .signup-section{padding: 20px; flex-direction: column;}
.project-details-page  .signup-section .ant-btn-primary{height: 42px;}
.related-projects-section{padding-top: 20px;}
.related-projects-section .heading{font-size: 20px !important;}
.related-projects-listing .row-item{padding: 30px 24px 24px;}
.related-projects-listing .bottom-section{flex-direction: column;}
.related-projects-listing .bottom-section a{width: 100%; padding-top: 10px;}
.related-projects-listing .bottom-section .details{width: 100%;}
.related-projects-listing .ant-carousel .slick-dots-bottom {bottom: -22px;}

.fs-30 {font-size: 24px;}
.product-image {height: 200px;}
.product-view-right-section .pl-20{padding-left: 0;}
.money-protection{margin-left: 0;}
.profile-basic-details .top{height: 65px;}

/* Login Page */
.login-main-page {background-image: none; min-height: 50vh;}
.login-page.items-end{justify-content: flex-start;}
.login-page .auth-block{width: 100%; margin-top: 30px; margin-right: 10px; margin-left: 10px;}
}
