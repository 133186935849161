.block-heading{color:#000; font-size:18px; font-weight:bold; line-height:1.3; margin-bottom:15px;}

.ant-empty-normal{margin:90px 0;}

.left-block .block-title{color:#434343; display:flex; justify-content:space-between; font-size:20px; font-weight:500; padding:18px 20px 10px;}
.left-block .block-title a{font-size:14px; align-self:center; color:#ee7d22; font-weight:normal}

div.ant-typography{margin-bottom:5px;}
.details-block{display:flex; justify-content:space-between;}
.details-block .block-heading{border:0px none; background-color:transparent; color:#003965; padding:0px; margin:20px 0; font-weight:500; font-size:20px;}

.user-switch{color:#003965; font-size:25px; font-weight:500;}
.user-switch > span:first-child{margin-right:10px;}
.user-switch > span:last-child{margin-left:10px;}

.user-switch > span.dull{opacity:.5}
.user-switch > span.red{color:#962c08}

.dashboard-page .right-block .details-block .full-section{width: 100%;}

.user-switch .ant-switch.red{background-image:linear-gradient(to right, rgba(150, 44, .2), rgba(150, 44, .2)), linear-gradient(to right, rgba(150, 44, 8), rgba(150, 44, 8, .7));}