.inner-page .heading{font-size:30px; font-weight:600;}

.main-block{background-image:url(../images/how-we-work/why-cogniticx-bg.png); background-repeat:no-repeat; background-position:center 380px; margin-top:60px;}
.main-block .blocks{display:flex; justify-content:space-between;}
.main-block .blocks .content-block{width:calc(100% - 400px);}
.main-block .blocks .image-block{align-items:center; display:flex; flex-direction:column; justify-content:center; width:375px;}

.main-block .content-block .point{display:flex; justify-content:space-between; line-height:1.5;}
.main-block .blocks .text-block{width:calc(100% - 70px);}
.main-block .blocks .img-block{margin-right:15px; width:58px;}
.point{color:#434343; margin-bottom:40px;}
.point .heading{color:#003965; font-size:20px; font-weight:500; line-height:1.5;}
.video-block{margin-top:30px;}

.point p {margin-bottom: 0.5em;}

.client-sp-block{margin-top:50px;}
.faqs-block{margin-top:80px;}
.faqs-block .heading{font-size:40px; font-weight:600; margin-bottom:30px;}

.ant-collapse{background-color:transparent; border:0px none; font-size:20px; margin-bottom:10px; overflow:hidden;}
.ant-collapse-item .ant-collapse-header{background-color:#F8F8F8; font-size:22px; color:#003965 !important; font-weight:500; padding:15px 40px 15px 25px;}
.ant-collapse-item-active .ant-collapse-header{background-color:#a24921; color:#fff !important; font-weight:600;}
.ant-collapse-content-box{color: #464E5F; padding:15px 25px;}
.ant-collapse > .ant-collapse-item{border-bottom:0px none; margin-bottom:5px;}

.client-sp-block .img-text-section{margin-top:50px;}
.client-sp-block .img-block{position:relative; padding:50px; padding-right:0px;}
.client-sp-block .img-text-section:not(.rev-row) .img-block{padding-left:0px; padding-right:50px;}
.client-sp-block .img-block::before{background-color:#FEE2B2; border-radius:5px; content:''; display:block; height:100%; position:absolute; left:0px; top:0px; width:calc(100% - 100px);}
.client-sp-block .img-text-section:not(.rev-row) .img-block::before{left:auto; right:0px;}
.client-sp-block .img-block img{z-index:999;}

.client-sp-block .num{background-color:#FEE2B2; border-radius:5px; -o-border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; color:#000; display:flex; flex-direction:column; font-size:24px; font-weight:600; justify-content:center; height:62px; margin-bottom:30px; text-align:center; width:62px;}
.client-sp-block .heading{font-size:24px; font-weight:600;}
.client-sp-block .block{ line-height:1.5; padding:100px 125px; font-size: 16px;}
.client-sp-block .img-text-section:not(.rev-row) .block{padding-left:50px;}
.client-sp-block .block ul{padding-left:15px;}
.client-sp-block .block ul li{margin-bottom:15px; position:relative;}
.client-sp-block .block ul li::before{content:"."; color:#F07E22; font-size:30px; font-weight:bold; display:inline-block; left:-15px; line-height:0; position:absolute; top:1px;}

.how-we-work-page .ant-tabs-nav-list{border: solid 1px #a24921;}
.how-we-work-page .ant-tabs-tab{font-size: 18px; padding: 10px 20px; z-index: 2;}
.how-we-work-page .ant-tabs-tab + .ant-tabs-tab{margin-left: 0;}
.how-we-work-page .ant-tabs-tab.ant-tabs-tab-active{color: #fff;}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{background-color: #a24921;height: 54px; z-index: 0;}
.ant-collapse{margin-top: 30px; font-size: 18px;}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{transform: scale(1.8);}

@media only screen and (max-width:767px){
    .fs-35{font-size: 28px;}
    .banner-block{min-height: 86px;}
    .mb-50{margin-bottom: 10px;}
    .main-block{margin-top: 20px;}
    .main-block > .container > .heading{margin-bottom: 25px; font-size: 22px;}
    .main-block .content-block .point {flex-direction: column;}
    .main-block .blocks .content-block{width: 100%;}
    .main-block .blocks{flex-direction: column;}
    .main-block .blocks .text-block{width: 100%;}
    .client-sp-block .img-text-section{margin-top: 0;}
    .img-text-section .text-block .block {padding: 10px; }
    .client-sp-block .num { height: 48px; width: 48px; margin-bottom: 10px; margin-top: 10px;}
    .client-sp-block .block{font-size: 18px;}
    .client-sp-block .img-block {padding: 20px;}
    .client-sp-block .img-text-section:not(.rev-row) .block{padding-left: 0;}
    .img-text-section {flex-direction: column-reverse; }
    .ant-collapse-item .ant-collapse-header {font-size: 18px; line-height: 1.3715 !important;}
    .faqs-block{margin-top: 50px;}
    .faqs-block .heading{font-size: 30px; text-align: left; padding-left: 10px;}
}
